import {AuthPagesConfig} from "../@WUM/templates/default/Auth/modules";
import {CMSRouteConfig} from "../modules/CMS";
import {MatomoPagesConfig} from "../modules/matomo/modules";
import {TicketPagesConfig} from "../modules/ticketsystem/modules";
import {monitoringPagesConfig} from "../modules/monitoring/modules";
import {ShopRouteConfig} from "../modules/shop";
import {SettingsRouteConfig} from "../modules/settings";



export const RegisterRoute = (value: any) => {
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...SettingsRouteConfig(value),
            ]
        case 'CMSwithMatomo':
            return [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...MatomoPagesConfig(value),
                ...SettingsRouteConfig(value)
            ]
        case 'SHOP':
        case 'ProductCatalog':
            return [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...ShopRouteConfig(value),
                ...SettingsRouteConfig(value)
            ]
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            return [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...MatomoPagesConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...ShopRouteConfig(value),
                ...SettingsRouteConfig(value)
            ]
        default:
            return [
                ...AuthPagesConfig(value),
                ...CMSRouteConfig(value),
                ...MatomoPagesConfig(value),
                ...TicketPagesConfig(value),
                ...monitoringPagesConfig(value),
                ...ShopRouteConfig(value),
                ...SettingsRouteConfig(value)
            ]
    }
}
