import React from 'react';
import configuration from '../configuration/seo';
import PielersForm from '../../../form-serilizer'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner';
import IntlMessage from '../../const/IntlMessage';
import ElementsSEOInterface from "../interface/ElementsSEO.interface";

function seo(props:ElementsSEOInterface){

  let Store = props.Store;
  if(props.response === 'new') {
    let title = (props.seo_title !== '' ? props.seo_title : '');
    let url = (props.seo_title !== '' && props.seo_title !== null && props.seo_title !== undefined ? props.seo_title.toLowerCase().replaceAll(' ','-')  : '');
    let description = (props.seo_description !== '' ? props.seo_description : '');
    return (
        <>
          {configuration('titel',[],true,Store)}
          {configuration('description',[],true,Store)}
          <hr />
          <Row className="g-2">
            <Col md>
              {PielersForm(configuration('colOne',[],true,Store),props.setseo_title,props.setseo_description)}
            </Col>
            {/*<Col md>
              <div style={{paddingLeft: '10px'}}>
                {PielersForm(configuration('colTwo',[],true,Store))}
              </div>
            </Col>*/}
          </Row>
          <Row>
            <hr />
            <Col>
              <h4><IntlMessage Store={Store} messageId="seo.preview" /></h4>
              <p><IntlMessage Store={Store} messageId="seo.preview.description" /></p>
            </Col>
            <Col>
              <div style={{fontSize: '13px', lineHeight: '1.4px', color: '#545454', fontStyle: 'normal', padding: '30px', border: '3px solid #ccc7d1', width: '550px'}}>
                <p style={{padding: '0px',margin: '0px',fontSize: '18px',lineHeight: '1.2',color: '#1a0dab'}}>{title}</p>
                <p style={{paddingTop: '2px',paddingBottom: '3px', margin: '0px',fontSize: '14px',lineHeight: '1.2',color: '#006621'}}>/{url}</p>
                <p style={{padding: '0px',margin: '0px',fontSize: '13px',lineHeight: '1.4',color: '#545454'}}>{description}</p>
              </div>

            </Col>
          </Row>
          <hr />
        </>
    )
  } else

  if(props.response !== undefined && props.response.hasOwnProperty('translation')) {
    const data = props.response.translation.de.seo;

      let title = (props.seo_title !== '' ? props.seo_title : (data !== null && data !== undefined && data.seo_title !== null ? data.seo_title : ''));
      let url = (props.seo_title !== '' ? props.seo_title.toLowerCase().replaceAll(' ','-')  : (data !== null && data !== undefined && data.seo_title !== null ? data.seo_title.toLowerCase(): ''));
      let description = (props.seo_description !== '' ? props.seo_description : (data !== null && data !== undefined && data.seo_description !== null ? data.seo_description : ''));

      return (
        <>
          {configuration('titel',data,false,Store)}
          {configuration('description',data,false,Store)}
          <hr />
          <Row className="g-2">
            <Col md>
              {PielersForm(configuration('colOne',data,false,Store),props.setseo_title,props.setseo_description)}
            </Col>
            {/*<Col md>
              <div style={{paddingLeft: '10px'}}>
                {PielersForm(configuration('colTwo',data,false,Store))}
              </div>
            </Col>*/}
          </Row>
          <Row>
            <hr />
            <Col>
              <h4><IntlMessage Store={Store} messageId="seo.preview" /></h4>
              <p><IntlMessage Store={Store} messageId="seo.preview.description" /></p>
            </Col>
            <Col>
              <div style={{fontSize: '13px', lineHeight: '1.4px', color: '#545454', fontStyle: 'normal', padding: '30px', border: '3px solid #ccc7d1', width: '550px'}}>
                <p style={{padding: '0px',margin: '0px',fontSize: '18px',lineHeight: '1.2',color: '#1a0dab'}}>{title}</p>
                <p style={{paddingTop: '2px',paddingBottom: '3px', margin: '0px',fontSize: '14px',lineHeight: '1.2',color: '#006621'}}>/{url}</p>
                <p style={{padding: '0px',margin: '0px',fontSize: '13px',lineHeight: '1.4',color: '#545454'}}>{description}</p>
              </div>

            </Col>
          </Row>
          <hr />
        </>
    )
  } else {
    return (
        <><Spinner animation="border" role="status">
          <span className="visually-hidden"><IntlMessage Store={Store} messageId="common.loading" /></span>
        </Spinner></>
    )
  }
}

export default seo;
