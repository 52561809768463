import React from 'react';
import PielersForm from '../../index';
import {base, basesettings} from './base';
import CopyElements from "../../../component/const/CopyElements";

import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";

function SitemapWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                 setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                 setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                 content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'sitemap'
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/sitemap.html',
    }
    ]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'sitemap'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>carouselItem_end</div>
        {WebBuilderElementsHead('Sitemap','sitemap','',Status,index,'elementButton','Text',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}

        <Col  className={'accordion-body'}>

        </Col>
        <div id={'settingstext'+index+'sitemap'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>
          {PielersForm(newConfiguration)}
          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
        {(newElement === false ? CopyElements(element.id,'Sitemap',Store) : <></>)}

    </Row>

    </>
  )


}

export default SitemapWebBuilder;
