import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import Cards from "../../../../../@WUM/core/component/const/dashboardCards";
import MergeTwoObjecttoArray from "../../../../../@WUM/core/component/const/MergeTwoObjecttoArray";
import {ShowDialog} from "../../../../../@WUM/core/sidebar/sidebar";

const DashbaordOverview = (props: { value: any }) => {
    const Store = useSelector((state: any) => state)


    let dashboard = [];
    let conf = configruation(Store);
    for(let x=0;x<conf.length;x++){
        if(conf[x].onClick !== undefined) {
            dashboard.push(
                <>
                    <Col lg={2}><Cards title={conf[x].title}
                                       href={conf[x].href}
                                       icon={conf[x].icon}
                                       undertitle={conf[x].undertitle}
                                       text={conf[x].text} Store={Store}
                                       onClick={conf[x].onClick}/></Col>
                </>
            )
        } else {
            dashboard.push(
                <>
                    <Col lg={2}><Cards title={conf[x].title}
                                       href={conf[x].href}
                                       icon={conf[x].icon}
                                       undertitle={conf[x].undertitle}
                                       text={conf[x].text} Store={Store}/></Col>
                </>
            )
        }
    }

    return (<>
        <Row className={'DashbaordRow text-center'}>
            {dashboard}
        </Row>
    </>)
}

export default DashbaordOverview;

const configruation = (Store:any) => {

    let config: any = [];
    config =  MergeTwoObjecttoArray(config,cms())

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'ProductCatalog':
        case 'SHOP':
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            config =  MergeTwoObjecttoArray(config,product())

    }
    config =  MergeTwoObjecttoArray(config,settings(Store))
    config =  MergeTwoObjecttoArray(config,monitoring())
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMSwithMatomo':
        case 'SHOPwithMatomo':
        case 'ProductCatalogwithMatomo':
            config =  MergeTwoObjecttoArray(config,matomo())

    }
    config =  MergeTwoObjecttoArray(config,itSupport())
    config =  MergeTwoObjecttoArray(config,galerie(Store))
    return config;



}



const itSupport = () => [
    {
        title: 'menu.item.dashboard',
        href: '/ticket/overview/1',
        undertitle:'IT-Support',
        icon: 'developer_board',
        text: 'menu.item.dashboard.description'
    },
    {
        title: 'menu.item.labels',
        href: '/ticket/Label/overview',
        undertitle:'IT-Support',
        icon: 'label',
        text: 'menu.item.labels.description'
    }
]

const monitoring = () => [
    {
        title: 'Dashboard',
        href: '/Monitoring/overview/1',
        undertitle:'Monitoring',
        icon: 'dashboard',
        text: 'monitoring.dashboard.description'
    },
    {
        title: 'Monitoring',
        href: '/Monitoring/API/overview/1',
        undertitle:'Monitoring',
        icon: 'api',
        text: 'monitoring.monitoring.description'
    }
]



const cms = () => [
    {
        title: 'cms.blog.header',
        href: '/Blog/1',
        undertitle:'CMS',
        icon: 'post_add',
        text: 'cms.blog.description'
    },
    {
        title: 'cms.page.header',
        href: '/Pages/overview/1',
        undertitle:'CMS',
        icon: 'account_tree',
        text: 'cms.page.description'
    },
    {
        title: 'cms.textblocks.header',
        href: '/TextBlocks/overview/1',
        undertitle:'CMS',
        icon: 'text_snippet',
        text: 'cms.textblocks.description'
    },
    {
        title: 'Formular',
        href: '/formular/1',
        undertitle:'CMS',
        icon: 'contact_mail',
        text: 'cms.formular.tooltip.content'
    },
    {
        title: 'cms.customize.header',
        href: '/customizer/1',
        undertitle:'CMS',
        icon: 'css',
        text: 'cms.customize.description'
    }
]

const matomo = () => [
    {
        title: 'matomo.menu.item.dashboard',
        href: '/analytics',
        undertitle:'Analytics',
        icon: 'equalizer',
        text: 'matomo.menu.item.dashboard.description'
    },
    {
        title: 'matomo.menu.item.visitor',
        href: '/analytics/visitor',
        undertitle:'Analytics',
        icon: 'visibility',
        text: 'matomo.menu.item.visitor.description'
    },
    {
        title: 'matomo.menu.item.behave',
        href: '/analytics/behave',
        undertitle:'Analytics',
        icon: 'psychology',
        text: 'matomo.menu.item.behave.description'
    },
    {
        title: 'matomo.menu.item.acquisition',
        href: '/analytics/acquisition',
        undertitle:'Analytics',
        icon: 'report',
        text: 'matomo.menu.item.acquisition.description'
    }
]

const product = () => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'SHOPwithMatomo':
            return [
                {
                    title: 'shop.product.header',
                    href: '/Product/overview/1',
                    undertitle:'Shop',
                    icon: 'shopping_bag',
                    text: 'shop.produkte.tooltip.content'
                },
                {
                    title: 'shop.orders.header',
                    href: '/Orders/overview/1',
                    undertitle:'Shop',
                    icon: 'monetization_on',
                    text: 'shop.bestellungen.tooltip.content'
                },
                {
                    title: 'shop.promotion.header',
                    href: '/Promotion/overview/1',
                    undertitle:'Shop',
                    icon: 'redeem',
                    text: 'shop.gutscheine.tooltip.content'
                },
                {
                    title: 'Profil',
                    href: '/merchant/Detail/'+process.env.REACT_APP_MERCHANT_PROFIL,
                    undertitle:'Shop',
                    icon: 'storefront',
                    text: 'shop.anbieter.tooltip.content'
                }
            ]
        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                {
                title: 'shop.product.header',
                href: '/Product/overview/1',
                undertitle:'Shop',
                icon: 'shopping_bag',
                text: 'shop.produkte.tooltip.content'
                },
                {
                    title: 'Profil',
                    href: '/merchant/Detail/'+process.env.REACT_APP_MERCHANT_PROFIL,
                    undertitle:'Shop',
                    icon: 'storefront',
                    text: 'shop.anbieter.tooltip.content'
                }
            ]
    }
}


const galerie = (Store:any) => [
    {
        title: 'menu.image.galery',
        href: '',
        onClick: ShowDialog,
        undertitle:'Media',
        icon: 'collections',
        text: 'media.galerie.tooltip.content'
    }
]

const settings = (Store:any) => {
    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'SHOP':
        case 'SHOPwithMatomo':
            if(process.env.REACT_APP_PROMOTIOM === 'True') {
                return [
                    {
                        title: 'common.settings.promo',
                        href: '/Promo/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'savings',
                        text: 'einstellungen.promotion.tooltip.content'
                    },
                    {
                        title: 'settings.Member.header',
                        href: '/Member/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'manage_accounts',
                        text: 'settings.member.description'
                    },
                    {
                        title: 'settings.shipping.header',
                        href: '/Shipping/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'local_shipping',
                        text: 'einstellungen.versandmethoden.tooltip.content'
                    },
                    {
                        title: 'settings.tax.header',
                        href: '/Tax/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'account_balance',
                        text: 'einstellungen.steuer.tooltip.content'
                    },
                    {
                        title: 'settings.unit.header',
                        href: '/Unit/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'scale',
                        text: 'einstellungen.einheiten.tooltip.content'
                    },
                    {
                        title: 'Template Settings',
                        href: '/Config/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'description',
                        text: 'einstellungen.template_settings.tooltip.content'
                    },
                    {
                        title: 'settings.taxon.header',
                        href: '/Taxon/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'category',
                        text: 'einstellungen.kategorien.tooltip.content'
                    }
                ]
            } else {
                return [
                    {
                        title: 'settings.Member.header',
                        href: '/Member/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'manage_accounts',
                        text: 'settings.member.description'
                    },
                    {
                        title: 'settings.shipping.header',
                        href: '/Shipping/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'local_shipping',
                        text: 'einstellungen.versandmethoden.tooltip.content'
                    },
                    {
                        title: 'settings.tax.header',
                        href: '/Tax/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'account_balance',
                        text: 'einstellungen.steuer.tooltip.content'
                    },
                    {
                        title: 'settings.unit.header',
                        href: '/Unit/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'scale',
                        text: 'einstellungen.einheiten.tooltip.content'
                    },
                    {
                        title: 'Template Settings',
                        href: '/Config/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'description',
                        text: 'einstellungen.template_settings.tooltip.content'
                    },
                    {
                        title: 'settings.taxon.header',
                        href: '/Taxon/overview/1',
                        undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                        icon: 'category',
                        text: 'einstellungen.kategorien.tooltip.content'
                    }
                ]
            }

        case 'ProductCatalog':
        case 'ProductCatalogwithMatomo':
            return [
                {
                    title: 'settings.Member.header',
                    href: '/Member/overview/1',
                    undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                    icon: 'manage_accounts',
                    text: 'settings.member.description'
                },
                {
                    title: 'Template Settings',
                    href: '/Config/overview/1',
                    undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                    icon: 'description',
                    text: 'einstellungen.template_settings.tooltip.content'
                },
                {
                    title: 'settings.taxon.header',
                    href: '/Taxon/overview/1',
                    undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                    icon: 'category',
                    text: 'einstellungen.kategorien.tooltip.content'
                }
            ]
        default:
            return[
                {
                    title: 'Template Settings',
                    href: '/Config/overview/1',
                    undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                    icon: 'description',
                    text: 'einstellungen.template_settings.tooltip.content'
                },
                {
                    title: 'settings.Member.header',
                    href: '/Member/overview/1',
                    undertitle:<IntlMessage Store={Store} messageId={'common.settings'}/>,
                    icon: 'manage_accounts',
                    text: 'settings.member.description'
                }
            ]
    }
}
