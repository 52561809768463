import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import SunEditorBig from '../../../component/const/SunEditorBig';
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";


function module_titleWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)




  const configuration = [
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline" />,
      'name': 'elements[' + index + '][translation][title]',
      'type': 'text',
      'placeholder': "lang[common.insert_headline]",
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.title : '') : ''),
    },
    SunEditorBig(element.translation,'text',newElement,data,'elements[' + index + '][translation][text]'),
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'image_title_image_'+index,
          'path': (newElement === false ? (element.media !== undefined ? element.media.cdn_url : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFileBucket',
          'onclick': true,
          'id': 'image_titleFormField'+index,
          'onclick_context': (newElement === false ? element.context : 'pielers_element_title_image'),
          'append': 'elements[' + index + '][image]',
          'src': 'image_title_image_'+index,
          'type': 'file',
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][image]',
      'id': 'elements[' + index + '][image]',
      'visible': true,
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? element.image : ''),
    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.button_text" />,
      'type': 'text',
      'max': 30,
      'placeholder': IntlMessageValue('de',"common.button_text",Store),
      'name': 'elements['+index+'][translation][cta_text]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.cta_text : '' ) : '' ),
    },
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.url" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.url",Store),
      'name': 'elements['+index+'][translation][url]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.url : '' ) : ''),
    }
    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'module_title'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>module_title</div>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="content.element.module_title.type" />,'module_title',(newElement === false ? (element.translation !== undefined ? element.translation.title : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="content.element.module_title.type" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Col  className={'accordion-body'}>
            {PielersForm(newConfiguration)}
            {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

          </Col>
        <div id={'settingstext'+index+'module_title'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="content.element.module_title.type"  Store={Store}/>,Store) : <></>)}

        </Row>

    </>
  )


}

export default module_titleWebBuilder;
