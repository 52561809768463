import React   from "react";
import SidebarItems from "../../templates/default/config/SidebarItems";
import {ProSidebar, Menu, SubMenu, SidebarHeader, SidebarContent, SidebarFooter} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import ItemMenu from "./ItemMenu";
import MenuIcon from "./MenuIcon";
//import Avatar from '@material-ui/core/Avatar';
//import ReactFlagsSelect from 'react-flags-select';
import {IntlMessageValue} from "../component/const/IntlMessage";
import ChangeHeader from "./ChangeHeader";
import {
    //useDispatch,
    useSelector} from "react-redux";
/*import {AppDispatch} from "../../../store";
import * as actions from '../../templates/default/localization/store/Actions/Locale/LocaleAction'*/
import './sidebar.css'
import Gallery from "../component/const/galery";
import {replaceChar} from "../component/const/Tooltip";

const Sidebar = (props: {setValue:any,value:any} ) => {


    let Store = useSelector((state: any) => state)
    /*const [selected, setSelected] = useState('DE');
    const dispatch : AppDispatch = useDispatch();
    const setter = (code: React.SetStateAction<string>) => {
        setSelected(code)
        props.setValue(code)

        dispatch(actions.getLocale(code as string) as any)
    }

    localStorage.setItem('locale',selected)*/

    return (
        <>
            <ProSidebar
                collapsed={false}
                toggled={false}
                breakPoint="md"
            >
                <SidebarHeader>
                    <div className={'sidebarHeader'}>
                        <a href={(process.env.REACT_APP_LANDINGPAGES !== undefined ? process.env.REACT_APP_LANDINGPAGES : '/Blog/overview/1')}><img src={process.env.REACT_APP_LOGO_IMG} height={60} alt={'Logo'} className={'SidebarLogo'} /></a>
                        {/*<ReactFlagsSelect
                            selected={selected}
                            onSelect={code => setter(code)}
                            countries={["US","DE"]}
                            customLabels={{"US": "EN-US","DE": "DE"}}
                            placeholder="Select Language"
                        />
                        <div>
                            <Avatar style={{float:'left'}}>MU</Avatar> <p className={'avatarName'} style={{paddingTop:'0px'}}>
                            Marcel Ugurcu
                            {Store.user.username}
                            <br /> <a href={"/Logout"}>{IntlMessageValue('de','logout',Store)}</a></p>
                        </div>*/}
                    </div>
                </SidebarHeader>
                <SidebarContent>



                {
                    SidebarItems(props.value).map((item, index)=> {
                        if(item.icon !== undefined && item.icon !== '') {
                            return (
                                <>
                                <Menu key={'Menu'+index} iconShape="circle">
                                    <SubMenu key={'SubMenu'+index} title={ChangeHeader({header:item.header,value:props.value,Store:Store})} icon={<MenuIcon icon={(item.icon !== undefined ? item.icon : '')} />} className={'first'}>

                                        <ItemMenu header={item.header} key={'ItemMenu'+index} items={item.menuitem} value={props.value} Store={Store} />

                                    </SubMenu>
                                </Menu>

                                </>
                            );
                        } else {
                            return (
                                <>
                                    <Menu key={'Menu'+index} iconShape="circle">
                                    <SubMenu key={'SubMenu'+index} title={IntlMessageValue(props.value,item.header,Store)} >

                                        <ItemMenu header={item.header} key={'ItemMenu'+index} items={item.menuitem} value={props.value} Store={Store} />

                                    </SubMenu>
                                   </Menu>
                                </>
                            );
                        }
                    })

                }

                </SidebarContent>
                <SidebarFooter className={'ta-center'}>
                    <div className="sidebar-btn-wrapper" >
                        <a
                            href={process.env.REACT_APP_LOGO_IMG_WUM_LINK !== undefined ? process.env.REACT_APP_LOGO_IMG_WUM_LINK : "https://wum-solution.de"}
                            target="_blank"
                            className="sidebar-btn"
                            rel="noopener noreferrer"
                        >
                            <img src={process.env.REACT_APP_LOGO_IMG_WUM} style={{maxHeight:'60px'}} alt={'Logo'}/>
                        </a>
                    </div>
                </SidebarFooter>
            </ProSidebar>
        </>
    );
}
export default Sidebar


export const ShowDialog = (Store:any) => {
    let eles =  document.getElementById('dialog') as HTMLDivElement;
    eles.style.display = 'block';
    Gallery('','','',Store,'',null,null,null,false)
}

