
import {MonitoringMenuConfig, MonitoringPagesConfig} from "./monitoring";

export const monitoringPagesConfig = (value: any) => {

    return [
        ...MonitoringPagesConfig(value)
    ];
}

export const monitoringMenuConfig = (value:any) => {

    return [
        {
            header: 'Monitoring',
            icon: 'monitor_heart',
            menuitem: [
                ...MonitoringMenuConfig(value)
            ]
        }
    ];
}
