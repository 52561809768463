import React from 'react';
import TemplateNew from "./Pages/new";
import Template from "./Pages/Template";
import TemplateDetail from "./Pages/TemplateDetail";

export const TemplateConfig = (value: any) => {

    return [
        {
            path: '/Template/overview/:page',
            elements: <Template value={value}/>,
        },
        {
            path: '/Template/new/new',
            elements: <TemplateNew value={value}/>,
        },

        {
            path: '/Template/new/:page',
            elements: <TemplateNew value={value}/>,
        },
        {
            path: '/Template/Detail/:id',
            elements: <TemplateDetail value={value}/>,
        },
        {
            path: '/Template/Delete/:page/:deleteID',
            elements: <Template value={value}/>,
        },

    ];
}

export const TemplateMenuConfig = (value:any) => {
    return [{
        name: 'Template',
        route: '/Template/overview/1',
        icon: 'build_circle'
    }
        ]
}
