import {PagesConfig, PagesMenuConfig} from "./Pages/modules";
import {BlogMenuConfig, BlogPagesConfig} from "./Blog/modules";
import {CustomizerMenuConfig, CustomizerPagesConfig} from "./customize/modules";
import {TextBlocksMenuConfig, TextBlocksPagesConfig} from "./TextBlocks/modules";
import {DashbaordMenuConfig, DashbaordPagesConfig} from "./dashboard/modules";
import {FormularConfig, FormularMenuConfig} from "./Formular/modules";
import {CustomizerJSMenuConfig, CustomizerJSPagesConfig} from "./customizeJS/modules";
import {EmailMenuConfig, EmailPagesConfig} from "./Email/modules";


export const CMSRouteConfig = (value:any) => {
    return [
        ...CustomizerPagesConfig(value),
        ...CustomizerJSPagesConfig(value),
        ...BlogPagesConfig(value),
        ...PagesConfig(value),
        ...EmailPagesConfig(value),
        //...SnippetPagesConfig(value),
        ...TextBlocksPagesConfig(value),
        ...DashbaordPagesConfig(value),
        ...FormularConfig(value)
    ]
}

export const CMSMenuConfig = (value:any) => {
        return [
            {
                header: 'CMS',
                icon: 'home',
                menuitem: [
                    ...DashbaordMenuConfig(value),
                    ...BlogMenuConfig(value),
                    ...PagesMenuConfig(value),
                    ...EmailMenuConfig(value),
                    //...SnippetMenuConfig(value),
                    ...TextBlocksMenuConfig(value),
                    ...FormularMenuConfig(value),
                    ...CustomizerMenuConfig(value),
                    ...CustomizerJSMenuConfig(value),
                ]
            },
        ];
    }

