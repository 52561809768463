import React from 'react';
import PielersForm from '../../index';
import {basesettings} from './base';
import IntlMessage from "../../../component/const/IntlMessage";
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";

function dividerWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                 setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                 setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                 content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'divider'
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][type]',
      'type': 'hidden',
      'value': element.type,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][id]',
      'type': 'hidden',
      'value': element.id,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][new]',
      'type': 'hidden',
      'value': (newElement === false ? false : true),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][position]',
      'type': 'hidden',
      'value': (newElement === false ? element.position : index),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][updated_at]',
      'type': 'hidden',
      'value': element.updated_at,
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][type_id]',
      'type': 'hidden',
      'value': (newElement === false ? element.type_id : window.location.pathname.split('/').pop()),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/divider.html',
    }
    ]

  let newConfiguration = configuration;

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'divider'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>divider</div>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.divider" />,'divider','',Status,index,'elementButton','Text',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
        <Col  className={'accordion-body'}>
          <div id={'preview-container_divider'+index}  className={'preview-container'}>
            {(newElement === false ?
                <div className={"module "+ element.class } id={ element.div_id +"_"+ element.id }>
                  <div className="element">
                    <hr className="divider my-4" />
                  </div>
                </div> : <></>)}
          </div>
          <div id={'edit_divider'+index} style={{display:'none'}}>
          {PielersForm(newConfiguration)}
          {/*<Accordion.Body>
            {PielersForm(newConfiguration)}
            <Tabs defaultActiveKey="Einstellungen" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

        </div>
          </Col>
        <div id={'settingstext'+index+'divider'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.divider"  Store={Store}/>,Store) : <></>)}

        </Row>

    </>
  )


}

export default dividerWebBuilder;
