import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";

function ctaWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
             setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
             setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
             content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.url" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.url",Store),
      'name': 'elements['+index+'][translation][url]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.url : '' ) : ''),
    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.button_text" />,
      'type': 'text',
      'max': 30,
      'placeholder': IntlMessageValue('de',"common.button_text",Store),
      'name': 'elements['+index+'][translation][cta_text]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.cta_text : '' ) : '' ),
    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.button_title" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.button_title",Store),
      'name': 'elements['+index+'][translation][cta_title]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.cta_title : '' ) : ''),
    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.target" />,
      'name': 'elements['+index+'][translation][target]',
      'selected': (newElement === false ? (element.translation !== undefined ? element.translation.de.target  : '' ) : ''),
      'options': [
        {
          'label': IntlMessageValue('de',"common.standard",Store),
          'value': 1
        },
        {
          'label': IntlMessageValue('de',"common.new_window",Store),
          'value': 2
        },
        {
          'label': IntlMessageValue('de',"common.parent_window",Store),
          'value': 3
        },
        {
          'label': IntlMessageValue('de',"common.same_window",Store),
          'value': 4
        }]

    },{ 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.css_class" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.css_class",Store),
      'name': 'elements['+index+'][Class]',
      'value': (newElement === false ? element.Class : '' ),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/cta.html',
    }]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));
  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'cta'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>cta</div>
        {WebBuilderElementsHead('CTA','cta',(newElement === false ? (element.translation !== undefined ? element.translation.de.cta_text : '' ) : '' ),Status,index,'elementButton','CTA',
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Col  className={'accordion-body'}>
            <div id={'preview-container_cta'+index}  className={'preview-container'}>
              {(newElement === false ?
                  <div className={"module cta "+ element.class } id={ element.div_id +"_"+ element.id }>
                    <div className="container">
                      <div className="module__content text-center my-3">
                        <a href={ element.translation.de.url }
                           title={ element.translation.de.cta_title }
                           target={(element.translation.de.target === 2 ? '_blank' : '_self')}
                           className={"btn"+ element.class }>
                          {element.translation.de.cta_text}
                        </a>
                      </div>
                    </div>
                  </div> : <></>)}
            </div>
            <div id={'edit_cta'+index} style={{display:'none'}}>
            {PielersForm(newConfiguration)}
            {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

            </div>
          </Col>
        <div id={'settingstext'+index+'cta'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
          {(newElement === false ? CopyElements(element.id,'CTA',Store) : <></>)}

        </Row>

    </>
  )


}

export default ctaWebBuilder;
