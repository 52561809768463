import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";

function imageWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
               setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
               setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
               content:any = [],setElements:any = [],Store:any,galerie?:any,
    galerieItems?:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'image_title_image_'+index,
          'path': (newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'image_titleFormField'+index,
          'onclick_context': (newElement === false ? element.context : 'pielers_element_title_image'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile':index+'galerie',
          'experiment': true,
          'append': 'elements[' + index + '][translation][image_id]',
          'src': 'image_title_image_'+index,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][translation][image_id]',
      'id': 'elements[' + index + '][translation][image_id]',
      'visible': true,
      'label': 'imageID',
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.image_id : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/image.html',
    }


    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'image'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>

        <div id={'Teaser'}>image</div>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.image" />,'image',(newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="content.element.image.type" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,true,'',element.id)}
        <Col  className={'accordion-body'}>
          <div id={'preview-container_image'+index}  className={'preview-container'}>
            {(newElement === false ?
            <div className={"module m20"+element.class} id={ element.div_id +"_"+ element.id }>
              <div className="container">
                <div className="element">
                  <figure>
                    <div className="figure__media">
                      <img className="lazy" src={(element.translation.de.media !== null ? element.translation.de.media.cdn_url : '')}
                           alt={ (element.translation.de.media !== null ? element.translation.de.media.name : '') }/>
                    </div>
                    <figcaption className="d-none">
                      {(element.translation.de.media !== null ?element.translation.de.media.name : '')}
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div> : <></>)}

          </div>
          <div id={'edit_image'+index} style={{display:'none'}}>
        {PielersForm(newConfiguration)}
        {/*<Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

          </div>
          </Col>
        <div id={'settingstext'+index+'image'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
        {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.image"  Store={Store}/>,Store) : <></>)}

      </Row>
    </>
  )


}

export default imageWebBuilder;
