import IntlMessage from "./IntlMessage";
import Button from 'react-bootstrap/Button';
/**
 * Globaler zurück Button
 * @param history
 * @param langKey
 * @param addedClass
 */
export const btn_back = (history:any,langKey:any = "common.abort",addedClass:any = '',Store:any) =>{
    return (
        <>
            <Button variant='light' style={{float: 'left', marginLeft: '15px'}} className={addedClass} onClick={() => history(-1)}>{<IntlMessage Store={Store} messageId={langKey} />}</Button>
        </>
    );
}
