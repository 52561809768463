import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";

function videoWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
               setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
               setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
               content:any = [],setElements:any = [],Store:any,galerie?:any,
    galerieItems?:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'type': 'video',
          'id': 'image_title_image_'+index,
          'path': (newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') : '') : ''),
          'width': '250',
          'height': '250',
          'class': 'elementsIMg',
          'size': {
            'xl': 3,
            'md': 3,
            'sm': 3,
          }
        },
        {
          'formType': 'formFileVideo',
          'onclick': true,
          'id': 'image_titleFormField'+index,
          'onclick_context': (newElement === false ? element.context : 'pielers_element_title_images'),
          'append': 'elements[' + index + '][translation][image_id]',
          'src': 'image_title_image_'+index,
          'srcType': 'video',
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile':index+'galerie',
          'experiment': true,
          'type': 'file',
          'size': {
            'xl':8,
            'md': 8,
            'sm': 8,
          }
        },

    {
      'formType': 'input',
      'name': 'elements[' + index + '][translation][image_id]',
      'id': 'elements[' + index + '][translation][image_id]',
      'visible': true,
      'label': 'imageID',
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.image_id : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/video.html',
    }
      ],
    },

    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'video'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>video</div>
        {WebBuilderElementsHead('UM02 - Video','video',(newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="content.element.image.type" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,false,false,element.id)}
        <Col  className={'accordion-body'}>
          <div id={'preview-container_video'+index}  className={'preview-container'}>
            {(newElement === false ?
                <div className={"video "+ element.class } id={ element.div_id +"_"+ element.id }>
                  <div className="element h-100">
                    <figure className="h-100">
                      <div className="figure__media h-100">
                        <video id="image_title_image_4" controls={true} className="h-100"
                               poster="/thumbnail/pirsonlogo_600.webp">
                          <source src={ (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') } type="video/mp4"/>
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </figure>
                  </div>
                </div> : <></>)}
          </div>
          <div id={'edit_video'+index} style={{display:'none'}}>
        {PielersForm(newConfiguration)}

          {/*<Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>
          </Accordion.Body>*/}

          </div>
        </Col>
        <div id={'settingstext'+index+'video'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
        {(newElement === false ? CopyElements(element.id,'UM02 - Video',Store) : <></>)}

    </Row>
    </>
  )


}

export default videoWebBuilder;
