import IntlMessage, {IntlMessageValue} from '../../const/IntlMessage';
import React from "react";
//import {AuthUser} from '../../../../../types/models/AuthUser';

function configuration(conf: string, response: any, newElement: any = false,Store:any) {

  let cutDate = ((newElement === false ? (response.translation.de.published_at !== undefined && response.translation.de.published_at !==null ? response.translation.de.published_at.split(' ')[0] : ' '):' '));

  const titel = (<><h2><IntlMessage Store={Store} messageId="configuration.home.title" /></h2></>);

  const description = (<><p><IntlMessage Store={Store} messageId="configuration.home.description" /></p></>);

  const dataColOne = [{
    'formType': 'input',
    'type': 'hidden',
    'name': 'type',
    'value': (newElement === false ? response.type : ''),
  },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'layout',
      'value': (newElement === false ? response.layout : ''),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'translations[locale]',
      'value': (newElement === false ?  response.translation.de.locale : 'de'),
    },{
      'formType': 'input',
      'type': 'hidden',
      'name': 'id',
      'value': (newElement === false ? response.id : ''),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'translations[id]',
      'value': (newElement === false ? response.translation.de.id : ''),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'translations[parent]',
      'value': (newElement === false ? response.translation.de.parent : ''),
    },
    {
      'formType': 'input',
      'type': 'hidden',
      'name': 'identifier',
      'value': (newElement === false ? response.identifier : ''),
    },{
    'formType': 'input',
    'label': <IntlMessage Store={Store} messageId="common.title" />,
     'required': true,
     'max': 70,
    'type': 'text',
    'placeholder': IntlMessageValue("de","common.provide_pagetitle",Store),
    'name': 'translations[title]',
    'value': (newElement === false ? response.translation.de.title : ''),
  },
    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.released" />,
      'type': 'date',
      'placeholder': 'xx.xx.xxxx',
      'name':'published_at',
      'value': cutDate,
    },
    {
      'formType': 'textarea',
      'label': <IntlMessage Store={Store} messageId="common.short_description" />,
      'type': 'textarea',
      'max': 160,
      'required': true,
      'forbidden': true,
      'row': 3,
      'name': 'translations[description]',
      'value': (newElement === false ? response.translation.de.description.replaceAll('\n',' ') : ''),
    },
    {
      'formType': 'fetchselect',
      'local': 'author',
      'valueState': 'id',
      'beginn': 'true',
      'start': 0,
      'name': 'author',
      'type': 'author',
      'labelState': 'username',
      'selected': (newElement === false ? response.author : 1),
      'label': <IntlMessage Store={Store} messageId="common.author" />
    },
  ];

  const dataColtwo = [{
    'formType': 'input',
    'type': 'text',
    'placeholder':  IntlMessageValue("de","common.change_url",Store),
    'label': <IntlMessage Store={Store} messageId="common.change_url.label" />,
    'required': true,
    'legende': IntlMessageValue("de",'common.change_url.legende',Store),
    'name': 'translations[slug]',
    'value': (newElement === false ? response.translation.de.slug.replaceAll('/','') : ''),
  },{
    'formType': 'select',
    'label': <IntlMessage Store={Store} messageId="common.visible" />,
    'name': 'visible',
    'selected': (newElement === false ? response.visible : 0),
    'options': [
      {
        'label': IntlMessageValue('de',"common.activated",Store),
        'value': '1'
      },
      {
        'label': IntlMessageValue('de',"common.deactivated",Store),
        'value': '0'
      }]

  },  {
    'formType': 'fetchselect',
    'local': 'fetchSelectBlogCategory',
    'valueState': 'id',
    'beginn': 'true',
    'start': 0,
    'name': 'parent',
    'labelState': 'title',
    'selected': (newElement === false ? response.parent : 0),
    'label': <IntlMessage Store={Store} messageId="common.page_parent" />
  },{
    'formType': 'multiply',
    'cols': [
      {
        'formType': 'image',
        'altLink': true,
        'id': 'image_title_image_',
        'path': (newElement === false ? (response.preview_media !== null ? (response.preview_media !== undefined ? response.preview_media.cdn_url : '') : '' ) : ''),
        'width': '44',
        'height': '44',
        'class': 'elementsIMg',
        'size': {
          'xl': 2,
          'md': 2,
          'sm': 2,
        }
      },
   /*   {
        'formType': 'formFileBucket',
        'onclick': true,
        'onclick_context': 'pielers_element_text_image',
        'append': 'preview_media',
        'src': 'image_title_image_',
        'type': 'file',
        'size': {
          'xl': 10,
          'md': 10,
          'sm': 10,
        }
      },*/
      {
        'formType': 'formFile',
        'onclick': true,
        'onclick_context': 'pielers_element_text_image',
        'append': 'preview_media',
        'src': 'image_title_image_',
        'experiment':true,
        'size': {
          'xl': 10,
          'md': 10,
          'sm': 10,
        }
      },
    ],
  },
    {
      'formType': 'input',
      'name': 'preview_media',
      'id': 'preview_media',
      'visible': true,
      'type': 'hidden',
      'placeholder': 'imageID',
      'value': (newElement === false ? (response.preview_media !== null ? (response.preview_media !== undefined ? response.preview_media.id : null) :null ) : null),
    }
  ]

  if(conf === 'titel') {
    return titel;
  }  else if(conf === 'description') {
    return description;
  } else if(conf === 'dataColOne') {
    return dataColOne;
  } else if(conf === 'dataColtwo') {
    return dataColtwo;
  }

}

export default configuration;
