import React from 'react';
import PielersForm from '../../index'
import {Accordion, Row, Tabs} from 'react-bootstrap';
import {base, basesettings} from './base';
import {ElementsHead} from '../../../component/const/ElementsHead';
import IntlMessage from '../../../component/const/IntlMessage';
import Col from 'react-bootstrap/Col';
import Tab from "react-bootstrap/Tab";
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";

function galleryWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
                           setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                           setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                           content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {}
  ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let images = element.imageHolder;
  let Holder = [];
  let iterator = 0;
  if(images !== undefined && images.length > 0) {
    for (let x = 0; x < images.length; x++) {
      let config = [
        {
          'formType': 'formFileBucket',
          'onclick': true,
          'id': 'image_titleFormField_' + x + '_' + index,
          'onclick_context': (newElement === false ? element.context : 'pielers_element_title_image'),
          'append': 'elements[' + index + '][image' + x + ']',
          'src': 'image_title_image_' + x + '_' + index,
          'type': 'file',
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ]
      Holder.push(
          <>
            <Col>
              <div className="card">
                <img className="card-img-top" id={'image_title_image_' + x + '_' + index} width={360} height={100}
                     style={{maxHeight: '100px !important'}} src={images[x].media.cdn_url} alt={images[x].media.name} />
                <div className="card-body">
                  <h5 className="card-title">Link</h5>
                  <p className="card-text">
                    <input type={'text'} value={images[x].link} name={'elements[' + index + '][link' + x + ']'} />
                  </p>
                  <h5 className="card-title">Media ID</h5>
                  <p className="card-text">
                    <input type={'text'} value={images[x].media.id} id={'elements[' + index + '][image' + x + ']'}
                           name={'elements[' + index + '][image' + x + ']'} />
                  </p>
                  {PielersForm(config)}
                </div>
              </div>
            </Col>
          </>
      )
      iterator = iterator + 1;
    }
  }

  for(let z = iterator; z<10;z++) {
    let config = [
      {
        'formType': 'formFileBucket',
        'onclick': true,
        'id': 'image_titleFormField_'+z+'_'+index,
        'onclick_context': (newElement === false ? element.context : 'pielers_element_title_image'),
        'append': 'elements[' + index + '][image'+z+']',
        'src': 'image_title_image_'+z+'_'+index,
        'type': 'file',
        'size': {
          'xl': 10,
          'md': 10,
          'sm': 10,
        }
      },
    ]
    Holder.push(
        <>
          <Col>
            <div className="card">
              <img className="card-img-top" id={'image_title_image_'+z+'_'+index} width={360} height={100} src='' alt='' style={{maxHeight: '100px !important'}}/>
              <div className="card-body">
                <h5 className="card-title">Link</h5>
                <p className="card-text">
                  <input type={'text'} value='' name={'elements[' + index + '][link'+z+']'} />
                </p>
                <h5 className="card-title">Media ID</h5>
                <p className="card-text">
                  <input type={'text'} value=''  id={'elements[' + index + '][image'+z+']'} name={'elements[' + index + '][image'+z+']'} />
                </p>
                {PielersForm(config)}
              </div>
            </div>
          </Col>
        </>
    )
  }

  let Imagesplacefirst = [];

  for(let first=0;first <5;first++){
    Imagesplacefirst.push(Holder[first])
  }

  let Imagesplacesecond = [];

  for(let second=5;second <10;second++){
    Imagesplacesecond.push(Holder[second])
  }

  let Place = [];

  Place.push(<><Row style={{marginBottom: '10px'}}>{Imagesplacefirst}</Row></>)
  Place.push(<><Row>{Imagesplacesecond}</Row></>)


  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
      <>
        <Row  id={'gallery'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
          <div id={'Teaser'}>gallery</div>
          {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="content.element.gallery.type" />,'gallery','',Status,index,'elementButton',<IntlMessage Store={Store} messageId="content.element.gallery.type" />,
              modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Col  className={'accordion-body'}>
            {PielersForm(newConfiguration)}
            {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
                <Row>
                  {Place}
                </Row>
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}
            <div id={'settingstext'+index+'gallery'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

              {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
            </div>
          </Col>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="content.element.gallery.type"  Store={Store}/>,Store) : <></>)}

        </Row>

      </>
  )


}

export default galleryWebBuilder;
