import Button from "react-bootstrap/Button";
import IntlMessage from "../../../../@WUM/core/component/const/IntlMessage";
import React from "react";
import {useSelector} from "react-redux";
import {notify_save} from "../../../../@WUM/core/component/const/notification.service";
import {error} from "../../../../@WUM/core/component/const/error.services";
import {useNavigate} from "react-router-dom";
import PielersForm from "../../../../@WUM/core/form-serilizer";
import {ResponseConfigAll} from "../API/response/config/config";
import {SpinnerJSX} from "../../../../@WUM/core/component/const/Spinner";
import {Form} from "react-bootstrap";
import {apiRoot} from "../../../../@WUM/core/RestFullApi/ApiHook";


const Configdetail = (value: any) => {
    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    const response = ResponseConfigAll('0', '')
    /**
     * Neuladen der Seite
     */
    const routeChange = () => {
        let path = `/Config/overview/1`;
        history(path);
    };

    const fields = (id: any, type: any) => {

        if (type === 'select') {
            return ((document.getElementById(id) as HTMLSelectElement) !== undefined && (document.getElementById(id) as HTMLSelectElement) !== null ? (document.getElementById(id) as HTMLSelectElement).value : null)
        } else if (type === 'input') {
            return ((document.getElementById(id) as HTMLInputElement) !== undefined && (document.getElementById(id) as HTMLInputElement) !== null ? (document.getElementById(id) as HTMLInputElement)?.value : null)
        }
    }

    /**
     * Sendet Formular Daten an die API
     * @param status
     * @param id
     */
    const save = (template:any) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));
        myHeaders.append("Content-Type", "application/json");


        var raw = JSON.stringify([

            {
                "css": fields('border-color', 'input'),
                "templateName": template,
                "key": "border-color"
            },
            {
                "css": fields('border-width', 'input'),
                "templateName": template,
                "key": "border-width"
            },
            {
                "css": fields('border-style', 'select'),
                "templateName": template,
                "key": "border-style"
            },
            {
                "css": fields('border-radius', 'input'),
                "templateName": template,
                "key": "border-radius"
            },
            {
                "css": fields('box-shadow-type', 'select'),
                "templateName": template,
                "key": "box-shadow-type"
            },
            {
                "css": fields('box-shadow-x-offset', 'input'),
                "templateName": template,
                "key": "box-shadow-x-offset"
            },
            {
                "css": fields('box-shadow-y-offset', 'input'),
                "templateName": template,
                "key": "default-box-y-offset"
            },
            {
                "css": fields('default-box-blur', 'input'),
                "templateName": template,
                "key": "default-box-blur"
            },
            {
                "css": fields('default-box-spread', 'input'),
                "templateName": template,
                "key": "default-box-spread"
            },
            {
                "css": fields('default-box-color', 'input'),
                "templateName": template,
                "key": "default-box-color"
            },
            {
                "css": fields('primary-color', 'input'),
                "templateName": template,
                "key": "primary-color"
            },
            {
                "css": fields('secondary-color', 'input'),
                "templateName": template,
                "key": "secondary-color"
            },
            {
                "css": fields('tertiary-color', 'input'),
                "templateName": template,
                "key": "tertiary-color"
            },
            {
                "css": fields('background-color', 'input'),
                "templateName": template,
                "key": "background-color"
            },
            {
                "css": fields('font-color', 'input'),
                "templateName": template,
                "key": "font-color"
            },
            {
                "css": fields('font-family', 'input'),
                "templateName": template,
                "key": "font-family"
            },
            {
                "css": fields('font-size', 'input'),
                "templateName": template,
                "key": "font-size"
            },
            {
                "css": fields('font-weight', 'input'),
                "templateName": template,
                "key": "font-weight"
            },
            {
                "css": fields('letter-spacing', 'input'),
                "templateName": template,
                "key": "letter-spacing"
            },
            {
                "css": fields('white-space', 'input'),
                "templateName": template,
                "key": "white-space"
            },
            {
                "css": fields('logo_src', 'input'),
                "templateName": template,
                "key": "logo"
            },
            {
                "css": fields('favicon_src', 'input'),
                "templateName": template,
                "key": "favicon"
            },
        ]);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };


        fetch(apiRoot + "template/css/Single/0/", requestOptions)
            .then(response => response.text())
            .then(result => {
                notify_save(Store);
                routeChange();
            })
            .catch(e => {
                error(e)
            });
    };
    if (typeof response !== "boolean" && response?.length > 0) {
        return (
            <>
                <div key={'head'}>
                    <div className={'MainBackground'} style={{backgroundColor: 'white', padding: '15px'}}
                         key={'button'}>
                        <Form>
                            <h2><IntlMessage Store={Store} messageId="common.template.settings.header"/></h2>
                            <hr/>
                            {PielersForm(ConfigForm('', 0, Store, response))}
                            <br/>
                            <Button variant='light' style={{float: 'left', marginLeft: '15px'}}><IntlMessage
                                Store={Store} messageId="common.cancel"/></Button>
                            <Button variant='success'
                                    style={{float: 'right', marginRight: '15px'}}
                                    type={'button'}
                                    id={'btn_save'}
                                    onClick={() => save(response[1].templateName)}
                            >
                                <IntlMessage Store={Store} messageId="common.save"/>
                            </Button>
                            <br style={{clear: 'both'}}/>
                        </Form>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }
}

export default Configdetail;

function ConfigForm(data: any, x: any, Store: any, response: any) {

    const dataColOne = [
        {
            'formType': 'header',
            'type': 'h5',
            'value': <IntlMessage Store={Store} messageId="common.template.settings.graphics.settings"/>,
        },
        {
            'formType': 'multiply',
            'cols': [
                {
                    'formType': 'image',
                    'altLink': true,
                    'id': 'Logo_Image',
                    'path': mapping('logo', response),
                    'width': '44',
                    'height': '44',
                    'class': 'elementsIMg',
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },{
                    'formType': 'formFile',
                    'onclick': true,
                    'id': 'logo',
                    'onclick_context': 'marketplace_market_square_400',
                    'expandfile': 'logo_id',
                    'mode':true,
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.graphics.settings.logo"/>,
                    'append': 'logo_src',
                    'src': 'Logo_Image',
                    'experiment': true,
                    'size': {
                        'xl': 9,
                        'md': 9,
                        'sm': 9,
                    }
                }, {
                    'formType': 'input',
                    'name': 'logo_src',
                    'id': 'logo_src',
                    'visible': true,
                    'label': 'Logo src',
                    'type': 'hidden',
                    'placeholder': 'imageID',
                    'value': mapping('logo', response),
                    'size': {
                        'xl': 1,
                        'md': 1,
                        'sm': 1,
                    }
                },
                {
                    'formType': 'image',
                    'altLink': true,
                    'id': 'Favicon_Image',
                    'path': mapping('favicon', response),
                    'width': '44',
                    'height': '44',
                    'class': 'elementsIMg',
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'formFile',
                    'onclick': true,
                    'id': 'favicon',
                    'onclick_context': 'marketplace_market_square_400',
                    'expandfile': 'favicon_id',
                    'mode':true,
                    'append': 'favicon_src',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.graphics.settings.favicon"/>,
                    'src': 'Favicon_Image',
                    'experiment': true,
                    'size': {
                        'xl': 9,
                        'md': 9,
                        'sm': 9,
                    }
                },{
                    'formType': 'input',
                    'name': 'favicon_src',
                    'id': 'favicon_src',
                    'visible': true,
                    'label': 'Favicon src',
                    'type': 'hidden',
                    'placeholder': 'imageID',
                    'value': mapping('favicon', response),
                    'size': {
                        'xl': 1,
                        'md': 1,
                        'sm': 1,
                    }
                },]
        },
        {
            'formType': 'header',
            'type': 'h5',
            'value': <IntlMessage Store={Store} messageId="common.template.settings.border.settings.header"/>,
            'SyntaxHighlighter':true,
            'SyntaxHighlighterValue':"var(--default-border) \nvar(--border-radius)",
            'SyntaxHighlighterLanguage':"css",
        },
        {
            'formType': 'multiply',
            'cols': [
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.border.settings.width"/>,
                    'type': 'text',
                    'id': 'border-width',
                    'placeholder': "border-width",
                    'legende': 'var(--border-width)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'value': mapping('border-width', response),
                    'size': {
                        'xl': 3,
                        'md': 3,
                        'sm': 3,
                    }
                },
                {
                    'formType': 'select',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.border.settings.style"/>,
                    'id': 'border-style',
                    'legende': 'var(--border-style)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'selected': mapping('border-style', response),
                    'options': [
                        {
                            'label': 'none',
                            'value': 'none',
                        }, {
                            'label': 'hidden',
                            'value': 'hidden',
                        },
                        {
                            'label': 'dotted',
                            'value': 'dotted',
                        }, {
                            'label': 'dashed',
                            'value': 'dashed',
                        },
                        {
                            'label': 'solid',
                            'value': 'solid',
                        }, {
                            'label': 'double',
                            'value': 'double',
                        },
                        {
                            'label': 'groove',
                            'value': 'groove',
                        }, {
                            'label': 'ridge',
                            'value': 'ridge',
                        },
                        {
                            'label': 'inset',
                            'value': 'inset',
                        }, {
                            'label': 'outset',
                            'value': 'outset',
                        },
                    ],
                    'size': {
                        'xl': 3,
                        'md': 3,
                        'sm': 3,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.border.settings.color"/>,
                    'type': 'color',
                    'id': 'border-color',
                    'legende': 'var(--border-color)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('border-color', response),
                    'size': {
                        'xl': 3,
                        'md': 3,
                        'sm': 3,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.border.settings.radius"/>,
                    'type': 'text',
                    'id': 'border-radius',
                    'legende': 'var(--border-radius)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('border-radius', response),
                    'size': {
                        'xl': 3,
                        'md': 3,
                        'sm': 3,
                    }
                },
            ]
        },
        {
            'formType': 'header',
            'type': 'h5',
            'value': <IntlMessage Store={Store} messageId="common.template.settings.box-shadow.settings.header"/>,
            'SyntaxHighlighter':true,
            'SyntaxHighlighterValue':"var(--default-box-shadow)",
            'SyntaxHighlighterLanguage':"css",
            'size': {
                'xl': 2,
                'md': 2,
                'sm': 2,
            }
        },
        {
            'formType': 'br',
        },
        {
            'formType': 'multiply',
            'cols': [
                {
                    'formType': 'select',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.box-shadow.settings.type"/>,
                    'id': 'box-shadow-type',
                    'legende': 'var(--box-shadow-type)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'selected': mapping('box-shadow-type', response),
                    'options': [
                        {
                            'label': 'outset',
                            'value': '',
                        }, {
                            'label': 'inset',
                            'value': 'inset',
                        },
                    ],
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.box-shadow.settings.x_offset"/>,
                    'type': 'text',
                    'id': 'box-shadow-x-offset',
                    'legende': 'var(--box-shadow-x-offset)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('box-shadow-x-offset', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.box-shadow.settings.y_offset"/>,
                    'type': 'text',
                    'id': 'default-box-y-offset',
                    'legende': 'var(--default-box-y-offset)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('default-box-y-offset', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.box-shadow.settings.blur"/>,
                    'type': 'text',
                    'id': 'default-box-blur',
                    'legende': 'var(--default-box-blur)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('default-box-blur', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.box-shadow.settings.spread"/>,
                    'type': 'text',
                    'id': 'default-box-spread',
                    'legende': 'var(--default-box-spread)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('default-box-spread', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.box-shadow.settings.color"/>,
                    'type': 'color',
                    'id': 'default-box-color',
                    'legende': 'var(--default-box-color)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('default-box-color', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
            ]
        },
        {
            'formType': 'header',
            'type': 'h5',
            'value': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.header"/>,
        },
        {
            'formType': 'br',
        },
        {
            'formType': 'multiply',
            'cols': [
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.primary"/>,
                    'type': 'color',
                    'id': 'primary-color',
                    'legende': 'var(--primary-color)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('primary-color', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.secondary"/>,
                    'type': 'color',
                    'id': 'secondary-color',
                    'legende': 'var(--secondary-color)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('secondary-color', response),
                    'size': {
                        'xl': 3,
                        'md': 3,
                        'sm': 3,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.tertiary"/>,
                    'type': 'color',
                    'id': 'tertiary-color',
                    'legende': 'var(--tertiary-color)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('tertiary-color', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.background"/>,
                    'type': 'color',
                    'id': 'background-color',
                    'legende': 'var(--background-color)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('background-color', response),
                    'size': {
                        'xl': 3,
                        'md': 3,
                        'sm': 3,
                    }
                }, {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.font"/>,
                    'type': 'color',
                    'id': 'font-color',
                    'legende': 'var(--font-color)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('font-color', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
            ]
        },
        {
            'formType': 'br',
        },
        {
            'formType': 'multiply',
            'cols': [
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.font_family"/>,
                    'type': 'text',
                    'id': 'font-family',
                    'legende': 'var(--font-family)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('font-family', response),
                    'size': {
                        'xl': 3,
                        'md': 3,
                        'sm': 3,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.font_size"/>,
                    'type': 'text',
                    'id': 'font-size',
                    'legende': 'var(--font-size)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('font-size', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                }, {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.font_weight"/>,
                    'type': 'text',
                    'id': 'font-weight',
                    'legende': 'var(--font-weight)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('font-weight', response),
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },
                {
                    'formType': 'input',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.letter_spacing"/>,
                    'type': 'text',
                    'id': 'letter-spacing',
                    'legende': 'var(--letter-spacing)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'placeholder': "lang[icon.code.provide]",
                    'value': mapping('letter-spacing', response),
                    'size': {
                        'xl': 3,
                        'md': 3,
                        'sm': 3,
                    }
                },
                {
                    'formType': 'select',
                    'label': <IntlMessage Store={Store} messageId="common.template.settings.font-color.settings.white_space"/>,
                    'id': 'white-space',
                    'legende': 'var(--white-space)',
                    'SyntaxHighlighter': true,
                    'SyntaxHighlighterLanguage':'css',
                    'selected': mapping('white-space', response),
                    'options': [
                        {
                            'label': 'normal',
                            'value': 'normal',
                        }, {
                            'label': 'nowrap',
                            'value': 'nowrap',
                        }, {
                            'label': 'pre',
                            'value': 'pre',
                        }, {
                            'label': 'pre-wrap',
                            'value': 'pre-wrap',
                        }, {
                            'label': 'pre-line',
                            'value': 'pre-line',
                        }, {
                            'label': 'break-spaces',
                            'value': 'break-spaces',
                        }
                        , {
                            'label': 'inherit',
                            'value': 'inherit',
                        }
                        , {
                            'label': 'initial',
                            'value': 'initial',
                        }
                        , {
                            'label': 'unset',
                            'value': 'unset',
                        }
                    ],
                    'size': {
                        'xl': 2,
                        'md': 2,
                        'sm': 2,
                    }
                },

            ]
        },
    ];

    return dataColOne;

}

const mapping = (key: any, response: any) => {


    for (let x = 0; x < response.length; x++) {
        if (response[x].key === key) {
            return response[x].css
        }
    }

}
