import React from 'react';
import PielersForm from '../../index'
import {Tabs} from 'react-bootstrap';
import {base, basesettings} from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";

function htmlWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  localStorage.setItem('HTMLModul'+index,(newElement === false ? (element.translation !== undefined ? element.translation.de.html : '') : ''))

  const configuration = [
    {
      'formType': 'SyntaxHighlight',
      'label': <IntlMessage Store={Store} messageId="comment.social.link" />,
      'type': 'textarea',
      'nochar': true,
      'id':'t1'+index,
      'index':index,
      'targetid': 'previewHolder' + index,
      'name': 'elements[' + index + '][translation][html]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.html : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/html.html',
    }]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'html_modul'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>html_modul</div>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.html_modul" />,'html_modul',(newElement === false ? (element.translation !== undefined ? element.translation.de.html : ''):''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.anchor" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Col  className={'accordion-body'}>
            <div id={'preview-container_html_modul'+index}  className={'preview-container'}>
              {(newElement === false ?
                  <div id={'previewHolder' + index} dangerouslySetInnerHTML={{__html: (newElement === false ? (element.translation !== undefined ? element.translation.de.html : '') : '')}} /> : <></>)}
            </div>
            <div id={'edit_html_modul'+index} style={{display:'none'}}>
            <Tabs defaultActiveKey="Preview" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={'Code'} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
            {/*  <Tab eventKey="Preview" title={'Preview'} key={'Preview'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                <div id={'previewHolder' + index} dangerouslySetInnerHTML={{__html: (newElement === false ? (element.translation !== undefined ? element.translation.de.html : '') : '')}} />
              </Tab>*/}
            </Tabs>

            </div>
          </Col>
        <div id={'settingstext'+index+'html_modul'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.html_modul"  Store={Store}/>,Store) : <></>)}

        </Row>

    </>
  )


}

export default htmlWebBuilder;
