/**
 * Erstellt den Header
 * @param name
 * @param nameID
 * @param Status
 * @param index
 * @param type
 * @param typeName
 * @param modalShow
 * @param setModalShow
 * @param modalData
 * @param setModalData
 * @param newElement
 * @param element
 * @param content
 * @param setElements
 * @constructor
 */
import { Col, Row} from "react-bootstrap";
import React from "react";
import IntlMessage from "./IntlMessage";
import deleteElementsWebBuilder from "../../form-serilizer/elements/helper/deleteElementsWebBuilder";

export const WebBuilderElementsHead  = (name:any,nameID:any,preview:any,Status:any,index:any,type:any,
                              typeName:any,modalShow:any,setModalShow:any,modalData:any,
                              setModalData:any,newElement:any,element:any,content:any = false,setElements:any = [],Store:any,isimgPreview:any =false,isVideoPreview:any =false,elementId:any = 0) => {

    if(newElement === false) {

    } else {

    }

    const openMoadl= () => {
        let modal = document.getElementById('ModalCopyElements'+elementId) as HTMLDivElement
        modal.style.display = "block";
    }


    const showElement = () => {
        let nav = document.getElementById('settingstext'+index+nameID) as HTMLDivElement;
        let Elemente = document.getElementsByClassName('settingsWebBuilderModul');



        if (nav.style.display === 'none') {
            nav.style.display = 'block'
        } else {
            nav.style.display = 'none'
        }
        for(var i = 0; i < Elemente.length; i++) {
            if((Elemente[i] as HTMLDivElement).id === 'settingstext'+index+nameID){

            } else{
                (Elemente[i] as HTMLDivElement).style.display ='none';
            }

        }

    }



    return (<><Row>
        <Col style={{width: '60px', maxWidth: '60px'}}>
            <h3  style={{height:'100%',marginTop: '-14px',padding:'0.08em 0em',width:'30px',textAlign:'left',background: 'rgba(0,0,0,.12)',position:'absolute',top:0,left:0}}>
        <span className="material-icons" style={{marginTop: '-0px',color:'black',padding: '5px'}}>
     open_with
</span></h3></Col><Col>
        {name}
        </Col><Col>

            {deleteElementsWebBuilder(nameID+''+index,'',<IntlMessage Store={Store} messageId="module.text" />,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements,Store)}


        <p  style={{height:'100%',marginTop: '-14px',width:'30px',textAlign:'left',float:'right',padding: '5px',background: 'rgba(0,0,0,.12)'}} onClick={() => showElement()}>
            <span className="material-icons">
            settings
            </span>
        </p>
            {(elementId !== 0 ? <p style={{height:'100%',marginTop: '-14px',width:'30px',textAlign:'left',float:'right',padding: '5px',background: 'rgba(0,0,0,.12)'}}  onClick={()=> openMoadl()} ><span
                className="material-icons">
file_copy
</span></p> : <></>)}
        <p  style={{height:'100%',marginTop: '-14px',width:'30px',textAlign:'left',float:'right',padding: '5px',background: 'rgba(0,0,0,.12)'}} onClick={() => showEditPreview(nameID,index)}>
            <span className="material-icons">
            edit
            </span>
        </p>
            {//preview
                 }

            {//deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements,Store)
                }
    </Col>

        {/* <Col style={{width: '50px', maxWidth: '50px',marginTop: '10px'}}>
      {deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements)}
    </Col>*/}
    </Row></>)
}

export default WebBuilderElementsHead;


export const showEditPreview = (nameID:any,index:any) => {
    let edit = document.getElementById('edit_'+nameID+index) as HTMLDivElement;
    let preview = document.getElementById('preview-container_'+nameID+index) as HTMLDivElement;
    let Elemente = document.getElementsByClassName('settingsWebBuilderModul');



    if (edit.style.display === 'none') {
        edit.style.display = 'block'
        preview.style.display = 'none'
    } else {
        edit.style.display = 'none'
        preview.style.display = 'block'
    }
    for(var i = 0; i < Elemente.length; i++) {
        if((Elemente[i] as HTMLDivElement).id === 'settingstext'+index+nameID){

        } else{
            (Elemente[i] as HTMLDivElement).style.display ='none';
        }

    }

}
