import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import SunEditorBig from '../../../component/const/SunEditorBig';
import CopyElements from "../../../component/const/CopyElements";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
function textWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    SunEditorBig((newElement === false && element.translation !== undefined  ? element.translation.de : ''),'text',newElement,data,
        'elements[' + index + '][translation][text]',''),
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/text.html',
    },
    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
        <Row  id={'text'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
          <div id={'Teaser'}>Text</div>
          {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.text" />,'text',(newElement === false && element.translation !== undefined  ? element.translation.de.text : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.text" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Col  className={'accordion-body'}>
            <div id={'preview-container_text'+index}  className={'preview-container'}>
              {(newElement === false ?
                  <div className={ element.class } id={ element.div_id+"_"+ element.id } dangerouslySetInnerHTML={{__html: element.translation.de.text}} />
                   : <></>)}
            </div>
            <div id={'edit_text'+index} style={{display:'none'}}>
            {PielersForm(newConfiguration)}
            {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

            </div>
          </Col>
          <div id={'settingstext'+index+'text'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

            {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
          </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.text"  Store={Store}/>,Store) : <></>)}

        </Row>

    </>
  )


}

export default textWebBuilder;
