import React from 'react';
import PielersForm from '../../index'
import {base} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
function search_resultWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                       setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                       setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                       content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.headline",Store),
      'name': 'elements['+index+'][translation][headline]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.headline : '') : ''),
    },
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.topline" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.topline",Store),
      'name': 'elements['+index+'][translation][topline]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.topline : '') : ''),
    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.search_intend" />,
      'name': 'elements['+index+'][search_entity]',
      'selected': (newElement === false ? element.search_entity : ''),
      'options': [
        {
          'label': IntlMessageValue('de',"common.products",Store),
          'value': 'products'
        },
        {
          'label': IntlMessageValue('de',"common.merchants",Store),
          'value': 'merchants'
        }]

    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.search_type" />,
      'name': 'elements['+index+'][search_type]',
      'selected': (newElement === false ? element.search_type : ''),
      'options': [
        /*{
          'label': IntlMessageValue("search.type.slider"),
          'value': 'slider'
        },
        {
          'label': IntlMessageValue("search.type.slider.single"),
          'value': 'slider_single'
        },*/
        {
          'label': IntlMessageValue('de',"search.type.list.filtered",Store),
          'value': 'list_filter'
        },
        {
          'label': IntlMessageValue('de',"search.type.list",Store),
          'value': 'list'
        },
        {
          'label': IntlMessageValue('de',"search.type.slider",Store),
          'value': 'slider'
        },
        /*{
          'label': IntlMessageValue('de',"search.type.map"),
          'value': 'map'
        }*/
        ]

    },
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.query" />,
      'type': 'text',
      'placeholder': IntlMessageValue('de',"common.query",Store),
      'name': 'elements['+index+'][search_term]',
      'value': (newElement === false ? element.search_term : ''),
    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="info.multi_add" />,
      'selected': (newElement === false ? element.addToCard : ''),
      'name': 'elements['+index+'][addToCard]',
      'options': [
        {
          'label': IntlMessageValue('de',"common.no",Store),
          'value': 0
        },
        {
          'label': IntlMessageValue('de',"common.yes",Store),
          'value': 1
        }]

    },{
      'formType': 'select',
      'label': <IntlMessage Store={Store} messageId="common.status" />,
      'br': true,
      'name': 'elements[' + index + '][visible]',
      'selected': (newElement === false ? element.visible : 1),
      'options': [
        {
          'label': IntlMessageValue('de',"common.activated",Store),
          'value': '1'
        },
        {
          'label': IntlMessageValue('de',"common.deactivated",Store),
          'value': '0'
        }]

    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/search_result.html',
    },]
  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'search_result'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>search_result</div>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.search" />,'search_result',(newElement === false ? (element.translation !== undefined ? element.translation.headline : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.search" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Col className={'accordion-body'}>

          </Col>
        <div id={'settingstext'+index+'search_result'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>
          {PielersForm(newConfiguration)}
        </div>
          {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.search"  Store={Store}/>,Store) : <></>)}

        </Row>

    </>
  )


}

export default search_resultWebBuilder;
