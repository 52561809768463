import React from 'react';
import FormElements from '../../../../../@WUM/core/form-serilizer/modul'
import configuration from '../configuration/content';
import {MediaMap, newModul, SocialMap, TextMap} from '../../../../../@WUM/core/form-serilizer/modul/newModul';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import {isEmptyArray} from 'formik';
import {Accordion} from 'react-bootstrap';
import ElementsContentInterface from "../../../../../@WUM/core/component/core/interface/ElementsContent.interface";
import ElementsContentAppendInterface
    from "../../../../../@WUM/core/component/core/interface/ElementsContentAppend.interface";
import {useSelector} from "react-redux";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {WebBuilderNav} from "../../../../../@WUM/core/form-serilizer/modulWebBuilder/newModul";

// wo kommt dieses element her
function append(props: ElementsContentAppendInterface) {

    let NewIndex;
    let NewIndexs;

    if (isEmptyArray(props.element)) {
        if (props.response.elements !== undefined) {
            NewIndex = (props.response.elements.length);
        } else {
            NewIndex = 0;
        }

        props.setIndex(NewIndex)
    } else {
        if (props.discr === 'row_start' || props.discr === 'slider_start' || props.discr === 'carouselItem_start') {
            NewIndex = props.Index + 1;
        } else {
            NewIndex = props.Index + 1;
        }

        props.setIndex(NewIndex)
    }

    let para = newModul(props.discr, NewIndex, props.setdeleteElement, props.element, props.Store, props.galerie, props.galerieItems)
    let allElements = [];
    for (let x = 0; x < props.element.length; x++) {
        allElements.push(props.element[x]);
    }
    if (props.discr === 'row_start' || props.discr === 'slider_start' || props.discr === 'carouselItem_start') {
        NewIndexs = NewIndex + 1;
        props.setIndex(NewIndexs)
    }
    allElements.push(para);
    props.setElement(allElements);

}

function Content(props: ElementsContentInterface) {

    const element = props.elements;
    const setElement = props.setElements;
    //const respo = props.response
    const Store = useSelector((state: any) => state)


// This function is triggered when the select changes
    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (value !== 'false') {

            append({
                response: props.response,
                setElement: setElement,
                discr: value,
                element: element,
                Index: props.Index,
                setIndex: props.setIndex,
                setdeleteElement: props.setdeleteElement,
                Store,
                galerie: props.galerie,
                galerieItems: props.galerieItems
            })

            let setselect = document.getElementById('newElement');
            if (setselect != null) {
                setselect.getElementsByTagName('option')[0].selected = Boolean(true)
            }
            let setselectMediaMap = document.getElementById('newElementMediaMap');
            if (setselectMediaMap != null) {
                setselectMediaMap.getElementsByTagName('option')[0].selected = Boolean(true)
            }
            let setselectSocialMap = document.getElementById('newElementSocialMap');
            if (setselectSocialMap != null) {
                setselectSocialMap.getElementsByTagName('option')[0].selected = Boolean(true)
            }
        }
    };

    const selectClick = (value: any = 'false') => {

        if (value !== 'false') {

            append({
                response: props.response,
                setElement: setElement,
                discr: value,
                element: element,
                Index: props.Index,
                setIndex: props.setIndex,
                setdeleteElement: props.setdeleteElement,
                Store,
                galerie: props.galerie,
                galerieItems: props.galerieItems
            })

            let setselect = document.getElementById('newElement');
            if (setselect != null) {
                setselect.getElementsByTagName('option')[0].selected = Boolean(true)
            }
            let setselectMediaMap = document.getElementById('newElementMediaMap');
            if (setselectMediaMap != null) {
                setselectMediaMap.getElementsByTagName('option')[0].selected = Boolean(true)
            }
            let setselectSocialMap = document.getElementById('newElementSocialMap');
            if (setselectSocialMap != null) {
                setselectSocialMap.getElementsByTagName('option')[0].selected = Boolean(true)
            }
        }
    };


    let optionTextMap = [];
    for (let xs = 0; xs < TextMap(props.Store).length; xs++) {

        let text: string | JSX.Element = '';
        if (TextMap(props.Store)[xs].value !== 'false') {
            text = TextMap(props.Store)[xs].label
        }
        optionTextMap.push(<>
            <option value={TextMap(props.Store)[xs].value}>{text}</option>
        </>)
    }

    let optionMediaMap = [];
    for (let xs = 0; xs < MediaMap(props.Store).length; xs++) {

        let text: string | JSX.Element = '';
        if (MediaMap(props.Store)[xs].value !== 'false') {
            text = MediaMap(props.Store)[xs].label
        }
        optionMediaMap.push(<>
            <option value={MediaMap(props.Store)[xs].value}>{text}</option>
        </>)
    }

    let optionSocialMap = [];
    for (let xs = 0; xs < SocialMap(props.Store).length; xs++) {

        let text: string | JSX.Element = '';
        if (SocialMap(props.Store)[xs].value !== 'false') {
            text = SocialMap(props.Store)[xs].label
        }
        optionSocialMap.push(<>
            <option value={SocialMap(props.Store)[xs].value}>{text}</option>
        </>)
    }


    let content;
    if (element !== undefined) {
        content = element;
    }



    let nav = [];
    let webbuild = WebBuilderNav(Store)
    for (let x = 0; x < webbuild.length; x++) {
        nav.push(
            <>
                <li className={'pro-menu-item pro-sub-menu text-black'} onClick={() => selectClick(webbuild[x].value)}>
                    <div className="pro-inner-item" role="button">
                        <span className="pro-icon-wrapper">
                          {webbuild[x].icon}
                        </span>
                        <span className="pro-item-content">{webbuild[x].label}</span>
                    </div>
                </li>
            </>
        )
    }

    const ElementMenu = (
        <>
            <div id="ElementMenu" className={'moduleNav'} style={{display: 'none'}}>
                <div className="pro-sidebar md">
                    <div className="pro-sidebar-inner">
                        <div className="pro-sidebar-layout">
                            <div className="pro-sidebar-header">
                                <div className="sidebarHeader"><a href="/">
                                    <img src="/uni_logo.png" height="60"
                                         alt="Logo" className="SidebarLogo"/></a>
                                </div>
                            </div>
                            <div className="pro-sidebar-content bgunset bg-white">
                                <nav className="pro-menu shaped circle inner-submenu-arrows">
                                    <ul>
                                        {nav}
                                    </ul>
                                </nav>
                            </div>
                            <div className="pro-sidebar-footer ta-center">
                                <div className="sidebar-btn-wrapper"><a href="https://wum-solution.de"
                                                                        target="_blank" className="sidebar-btn"
                                                                        rel="noopener noreferrer"><img
                                    src="/WUM Logo.png" alt="Logo" style={{maxHeight: '60px'}}/></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

    let ElementAdded = (
        <>
            <div className="react-page-editable react-page-editable-mode-edit" onClick={() => showElementNav()}>
                <div className="react-page-cell-insert-new"
                     style={{
                         pointerEvents: 'all',
                         zIndex: 1,
                         overflow: 'hidden',
                         //width: '50%',
                         minWidth: '120px',
                         margin: 'auto',
                         cursor: 'pointer'
                     }}>
                    <div className="react-page-cell-insert-new-icon">
                        <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="AddIcon">
                            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )




    if (props.response !== undefined && props.response.hasOwnProperty('elements') === false) {
        return (
            <><>
                {configuration('titel', props.response, true, Store)}
                {configuration('description', props.response, true, Store)}
                <hr/>
                {ElementMenu}
                <Accordion id={'elements'}>

                    {FormElements(configuration('default', props.response, true, Store), props.modalShow, props.setModalShow, props.modalData, props.setModalData, false, false, false, false, Store, props.galerie, props.galerieItems)}
                    {content}

                </Accordion>
                <hr/>
                {ElementAdded}
            </>
                {/*<Row>
                    <Col md>
                        <FloatingLabel controlId="floatingSelectGrid"
                                       label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                            <Form.Select id={'newElement'} aria-label="Floating label select example"
                                         onChange={selectChange}>
                                {optionTextMap}
                            </Form.Select>
                        </FloatingLabel>
                    </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionMediaMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionSocialMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                </Row>*/}
            </>
        )
    } else if (props.response !== undefined && props.response.hasOwnProperty('elements')) {

        return (
            <><>
                {ElementMenu}
                {configuration('titel', props.response, false, Store)}
                {configuration('description', props.response, false, Store)}
                <hr/>
                <Accordion id={'elements'} className="drag-box">
                    {FormElements(configuration('default', props.response, false, Store), props.modalShow, props.setModalShow, props.modalData, props.setModalData, false, false, false, false, Store, props.galerie, props.galerieItems)}
                    {content}
                </Accordion>
                <hr/>
            </>
                {ElementAdded}
                {/*<Row>
                    <Col md>
                        <FloatingLabel controlId="floatingSelectGrid"
                                       label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                            <Form.Select id={'newElement'} aria-label="Floating label select example"
                                         onChange={selectChange}>
                                {optionTextMap}
                            </Form.Select>
                        </FloatingLabel>
                    </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionMediaMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionSocialMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                </Row>*/}
            </>
        )
    } else {
        return (
            <><>
                {configuration('titel', props.response, true, Store)}
                {configuration('description', props.response, true, Store)}
                <hr/>
                {ElementMenu}
                <Accordion id={'elements'}>
                    {FormElements(configuration('default', props.response, true, Store), props.modalShow, props.setModalShow, props.modalData, props.setModalData, false, false, false, false, Store)}
                    {content}
                </Accordion>
                <hr/>
            </>
                {ElementAdded}
                {/*   <Row>
                    <Col md>
                        <FloatingLabel controlId="floatingSelectGrid"
                                       label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                            <Form.Select id={'newElement'} aria-label="Floating label select example"
                                         onChange={selectChange}>
                                {optionTextMap}
                            </Form.Select>
                        </FloatingLabel>
                    </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementMediaMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionMediaMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col><Col md>
                    <FloatingLabel controlId="floatingSelectGrid"
                                   label={<IntlMessage Store={props.Store} messageId="common.new_element"/>}>
                        <Form.Select id={'newElementSocialMap'} aria-label="Floating label select example"
                                     onChange={selectChange}>
                            {optionSocialMap}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                </Row>*/}
            </>
        )
    }
}

export default Content;


const showElementNav = () => {
    let nav = document.getElementById('ElementMenu') as HTMLDivElement;

    if (nav.style.display === 'none') {
        nav.style.display = 'block'
    } else {
        nav.style.display = 'none'
    }


}
