import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";

function cardWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
               setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
               setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
               content:any = [],setElements:any = [],Store:any,galerie?:any,
    galerieItems?:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'image_title_image_'+index,
          'path': (newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'image_titleFormField'+index,
          'onclick_context': (newElement === false ? element.context : 'pielers_element_title_image'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile':index+'galerie',
          'experiment': true,
          'append': 'elements[' + index + '][translation][image_id]',
          'src': 'image_title_image_'+index,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][translation][image_id]',
      'id': 'elements[' + index + '][translation][image_id]',
      'visible': true,
      'label': 'imageID',
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.image_id : '') : ''),
    },
    { 'formType': 'input',
      'label': 'image_alt',
      'type': 'text',
      'placeholder': 'image_alt',
      'name': 'elements['+index+'][translation][image_alt]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.image_alt : '' ) : '' ),
    },
    { 'formType': 'input',
      'label': 'headline',
      'type': 'text',
      'placeholder': 'headline',
      'name': 'elements['+index+'][translation][headline]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.headline : '' ) : '' ),
    },
    { 'formType': 'input',
      'label': 'text',
      'type': 'text',
      'placeholder': 'text',
      'name': 'elements['+index+'][translation][text]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.text : '' ) : '' ),
    },
    { 'formType': 'input',
      'label': 'link',
      'type': 'text',
      'placeholder': 'link',
      'name': 'elements['+index+'][translation][link]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.link : '' ) : '' ),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/card.html',
    }


    ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'card'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>

        <div id={'Teaser'}>image</div>
        {WebBuilderElementsHead('Card Module','card',(newElement === false ? (element.translation !== undefined ? (element.translation.de.media !== null ? element.translation.de.media.cdn_url : '') : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="content.element.image.type" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,true,'',element.id)}
        <Col  className={'accordion-body'}>
          <div id={'preview-container_card'+index}  className={'preview-container'}>
            {(newElement === false ?
                <div className={"col-lg-6 mb-3 mb-lg-0" +element.class} id={ element.div_id +"_"+ element.id }>
                  <div className="hover hover-1 text-white"><img
                      src={ element.translation.de.media.cdn_url }
                      alt={ element.translation.de.image_alt } />
                    <div className="hover-overlay"></div>
                    <div className="hover-1-content px-5 pt-4 text-center primary-background">
                      <a href={ element.translation.de.link }>
                        <h3 className="hover-1-title mb-0 secondary-color">{element.translation.de.headline}</h3>
                        <p className="hover-1-description font-weight-light mb-0 secondary-color">{element.translation.de.text}</p>
                      </a>
                    </div>
                  </div>

            </div> : <></>)}

          </div>
          <div id={'edit_card'+index} style={{display:'none'}}>
        {PielersForm(newConfiguration)}
        {/*<Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

          </div>
          </Col>
        <div id={'settingstext'+index+'card'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
        {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.image"  Store={Store}/>,Store) : <></>)}

      </Row>
    </>
  )


}

export default cardWebBuilder;
