import React from 'react';
import CustomTypesOverview from "./Pages/overview";
import CustomTypesDetail from "./Pages/Detail";
import RewriteDeleted from "./Pages/deleted";
import CustomTypesNew from "./Pages/new";
import {LanguageSwitcherValue} from "../../shop/core/components/LanguageSwitcher";

export const CustomTypesPagesConfig = (value:any) => {

  return [
      {
        path: '/customTypes/overview/:page',
        elements: <CustomTypesOverview value={value} />,
      },
      {
        path: '/customTypes/Detail/:id',
        elements: <CustomTypesDetail value={value} />,
      },
      {
        path: '/customTypes/Delete/:page/:deleteID',
        elements: <RewriteDeleted value={value} />,
      },
      {
        path: '/customTypes/new/',
        elements: <CustomTypesNew value={value} />,
      },
      {
          path: '/customTypes/new/:id',
          elements: <CustomTypesNew value={value} />,
      },
];
}

export const  CustomTypesMenuConfig = (value:any) => {
  return [
    {
      name: 'CustomTypes',
      route: '/customTypes/overview/1',
      icon: 'dashboard_customize'
    }
  ]
}
