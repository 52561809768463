import React from 'react';
import PielersForm from '../../index'
import {Tabs} from 'react-bootstrap';
import {base, basesettings} from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import Tab from "react-bootstrap/Tab";
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import "leaflet/dist/leaflet.css";

function mapWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                       setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                       setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                       content: any = [], setElements: any = [], Store: any) {

    const index = (newElement === false ? i : newelementIndex)

    const configuration = [

        {
            'formType': 'input',
            'label': 'street',
            'required': true,
            'name': 'elements[' + index + '][translation][street]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.street : '') : ''),
        },
        {
            'formType': 'input',
            'label': 'number',
            'required': true,
            'name': 'elements[' + index + '][translation][number]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.number : '') : ''),
        },


        {
            'formType': 'input',
            'label': 'city',
            'required': true,
            'name': 'elements[' + index + '][translation][city]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.city : '') : ''),
        },
        {
            'formType': 'input',
            'label': 'zip',
            'required': true,
            'name': 'elements[' + index + '][translation][zip]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.zip : '') : ''),
        },
        {
            'formType': 'input',
            'label': 'Ausgabe Text übern PIN',
            'name': 'elements[' + index + '][translation][content]',
            'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.content : '') : ''),
        },
        {
            'formType': 'input',
            'name': 'elements[' + index + '][country]',
            'type': 'hidden',
            'value': 'DE',
        },
        {
            'formType': 'input',
            'name': 'elements[' + index + '][template]',
            'type': 'hidden',
            'value': 'Elements/maps.html',
        }]

    let newConfiguration = configuration.concat(base(element, i, newElement, newelementIndex));

    let Status;
    if (element.visible === 1) {
        Status = 'elementActive';
    }
    if (element.visible === 0) {
        Status = 'elementDeactive';
    } else if (newElement === true) {
        Status = 'elementActive';
    }
    const position = [(newElement === false ? (element.translation !== undefined ? element.translation.de.lang : 53.60835) : 53.60835),
        (newElement === false ? (element.translation !== undefined ? element.translation.de.long : 8.53635) : 8.53635)]



    return (
        <>
            <Row id={'map' + index} className={'boxShadowCard card'}
                 style={{paddingBottom: '15px', paddingTop: '15px', width: '100%', margin: '0px 0px 10px 0px'}}>
                <div id={'Teaser'}>anchor</div>
                {WebBuilderElementsHead('Map', 'map', '', Status, index, 'elementButton', <IntlMessage Store={Store}
                                                                                                       messageId="module.anchor"/>,
                    modalShow, setModalShow, modalData, setModalData, newElement, element, content, setElements, Store, '', '', element.id)}
                <Col className={'accordion-body'}>


                   {/* <div>
                        <MapContainer
                            style={{ width: "100%", height: "100vh" }}
                            zoom={13}
                            center={[51.505, -0.09]}
                            scrollWheelZoom={false}
                            fadeAnimation={true}
                            markerZoomAnimation={true}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[51.505, -0.09]}>
                                <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>*/}


                    {/* <Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

                </Col>
                <div id={'settingstext' + index + 'map'} className={'settingsWebBuilderModul boxShadow '}
                     style={{display: 'none'}}>
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
                        <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general"/>}
                             key={'allgemein'} id={'allgemein'}
                             style={{backgroundColor: "White", padding: "15px", marginTop: "-15px"}}>
                            {PielersForm(newConfiguration)}
                        </Tab>
                        <Tab eventKey="Einstellungen"
                             title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'}/>}
                             key={'Einstellungen'}
                             style={{backgroundColor: "White", padding: "15px", marginTop: "-15px"}}>
                            {PielersForm(basesettings(element, i, newElement, newelementIndex, Store))}
                        </Tab>
                    </Tabs>

                </div>
                {(newElement === false ? CopyElements(element.id, 'Map', Store) : <></>)}

            </Row>
        </>
    )


}

export default mapWebBuilder;
