import React, {useState} from 'react';
import {useNavigate,useParams} from 'react-router-dom';
import {ResponseAuthor, ResponseDetail} from '../../API/response/blog/blog';
import {blogApiDetail} from '../../API/request/blog/blogApiDetail.interface';
import {email} from '../../API/request/blog/blog';
import {useLocalStorage} from "../../../../../@WUM/core/component/const/useLocalStoreage";
import FormCMS from "../../../../../@WUM/core/component/core/components/Form.cms";
import updateData from "../../../../../@WUM/core/component/core/components/updateData";
import BlogDetailTabsConfig from "../configuration/BlogDetailTabsConfig";
import {useSelector} from "react-redux";
import {ResponseformularALL} from "../../../Formular/API/response/formular/formular";
import {ResponseallProducts, ResponseallTaxon} from "../../../../shop/products/API/response/product/Product";
import FetchselectAPI from "../../../../../@WUM/core/component/const/fetchselectAPI";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";

/**
 * Give View BlogDetail for Content Blog
 * @memberOf blog
 * @prop id
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
const EmailDetail = (props:{value:any}) => {
  const {id} = useParams<{ id: string }>();
  const Store = useSelector((state: any) => state)
  const author = ResponseAuthor();
  const history = useNavigate();
  const response2 = ResponseDetail(id);
  const [currentTutorial, setCurrentTutorial] = useState<blogApiDetail[]>(response2);
  const [element, setElement] = useState([]);
  const debugdeleteElement = (element: any, author: blogApiDetail[]) => {
  }
  const [deleteElement, setdeleteElement] = useState(0);
  if (deleteElement !== 0) {
    debugdeleteElement(deleteElement, author)
  }
  const [modalShow, setModalShow] = React.useState(false);
  const [modalData, setModalData] = React.useState([]);
  const [validated, setValidated] = useState(false);
  const [seo_title, setseo_title] = useLocalStorage("seo_title", "");
  const [seo_description, setseo_description] = useLocalStorage("seo_description", "");
  const [Index, setIndex] = React.useState(0);

  if (response2.length > 0 ) {
    return (<>

      <FormCMS history={history} element={element} setElement={setElement} id={id} Index={Index} setIndex={setIndex}
               currentTutorial={currentTutorial}
               modalData={modalData} modalShow={modalShow} setModalShow={setModalShow} setModalData={setModalData}
               seo_title={seo_title}
               setseo_title={setseo_title} setseo_description={setseo_description} seo_description={seo_description}
               response2={response2}
               part={'blog'} setdeleteElement={setdeleteElement} setValidated={setValidated} validated={validated}
               NowRoute={email}
               setCurrentTutorial={setCurrentTutorial} updateData={updateData} config={BlogDetailTabsConfig}
               Store={Store} nonPreview={true}   />

    </>);
  } else {
    return (
        <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
    );
  }
}


export default EmailDetail;
