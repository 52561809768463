import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage, {IntlMessageValue} from '../../../component/const/IntlMessage';
import SunEditorBig from '../../../component/const/SunEditorBig';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";

function module_customer_stepsWebBuilder(data: any, element: any, i: any, newElement: any = false, newelementIndex: any = 0, modalShow: boolean,
                               setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                               setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
                               content:any = [],setElements:any = [],Store:any,galerie?:any,
                               galerieItems?:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [

    {
      'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.headline" />,
      'type': 'text',
      'name': 'elements[' + index + '][translation][headline]',
      'placeholder': IntlMessageValue('de',"common.headline",Store),
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.headline : '') : ''),
    },
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.topline" />,
      'type': 'text',
      'name': 'elements['+index+'][translation][topline]',
      'placeholder': IntlMessageValue('de',"common.topline",Store),
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.topline : '') : ''),
    },{
      'formType': 'divider',
    },
    SunEditorBig((newElement === false && element.translation !== undefined  ? element.translation.de : ''),'step_order',newElement,data,'elements[' + index + '][translation][step_order]',<IntlMessage Store={Store} messageId="module.block_n" preperator="replace" param="[n]" replace={1} />),
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'step_order'+index,
          'path': (newElement === false ? (element.translation.de.step_order_image !== undefined ? element.translation.de.step_order_image.cdn_url : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'editorial_step_order_image_idFormField'+index,
          'onclick_context': (newElement === false ? element.context: 'marketplace_market_square_400'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile':index+'step_order_image_id',
          'append': 'elements[' + index + '][translation][step_order_image_id]',
          'src': 'step_order'+index,
          'experiment': true,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][translation][step_order_image_id]',
      'id': 'elements[' + index + '][translation][step_order_image_id]',
      'visible': true,
      'label': 'imageID',
      'type': 'text',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.step_order_image_id : '') : ''),
    },
    {
      'formType': 'divider',
    },
    SunEditorBig((newElement === false && element.translation !== undefined  ? element.translation.de : ''),'step_delivered',newElement,data,'elements[' + index + '][translation][step_delivered]',<IntlMessage Store={Store} messageId="module.block_n" preperator="replace" param="[n]" replace={2} />),
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'step_delivered_image'+index,
          'path': (newElement === false ? (element.translation.de.step_delivered_image !== undefined ? element.translation.de.step_delivered_image.cdn_url : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'editorial_step_delivered_image_idFormField'+index,
          'onclick_context': (newElement === false ? element.context: 'marketplace_market_square_400'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'expandfile':index+'step_delivered_image_id',
          'append': 'elements[' + index + '][translation][step_delivered_image_id]',
          'src': 'step_delivered_image'+index,
          'experiment': true,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][translation][step_delivered_image_id]',
      'id': 'elements[' + index + '][translation][step_delivered_image_id]',
      'visible': true,
      'type': 'text',
      'label': 'imageID',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.step_delivered_image_id : '') : '' ),
    },
    {
      'formType': 'divider',
    },
    SunEditorBig((newElement === false && element.translation !== undefined  ? element.translation.de : ''),'step_dispatch_at',newElement,data,'elements[' + index + '][translation][step_dispatch_at]',<IntlMessage Store={Store} messageId="module.block_n" preperator="replace" param="[n]" replace={3} />),
    {
      'formType': 'multiply',
      'cols': [
        {
          'formType': 'image',
          'altLink': true,
          'id': 'step_dispatch_at'+index,
          'path': (newElement === false ? (element.translation.de.step_dispatch_at_image !== undefined ? element.translation.de.step_dispatch_at_image.cdn_url : '') : ''),
          'width': '44',
          'height': '44',
          'class': 'elementsIMg',
          'size': {
            'xl': 2,
            'md': 2,
            'sm': 2,
          }
        },
        {
          'formType': 'formFile',
          'onclick': true,
          'id': 'editorial_step_dispatch_at_image_idFormField'+index,
          'onclick_context': (newElement === false ? element.context: 'marketplace_market_square_400'),
          'Gallery': galerie,
          'galerieItems': galerieItems,
          'append': 'elements[' + index + '][translation][step_dispatch_at_image_id]',
          'expandfile':index+'step_dispatch_at_image_id',
          'src': 'step_dispatch_at'+index,
          'experiment': true,
          'size': {
            'xl': 10,
            'md': 10,
            'sm': 10,
          }
        },
      ],
    },{
      'formType': 'input',
      'name': 'elements[' + index + '][translation][step_dispatch_at_image_id]',
      'id': 'elements[' + index + '][translation][step_dispatch_at_image_id]',
      'visible': true,
      'type': 'text',
      'label': 'imageID',
      'placeholder': 'imageID',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.step_dispatch_at_image_id : '') : ''),
    },{
      'formType': 'divider',
    },
    { 'formType': 'input',
      'label': <IntlMessage Store={Store} messageId="common.button_text"/>,
      'type': 'text',
      'max': 30,
      'name': 'elements['+index+'][translation][cta_text]',
      'placeholder': IntlMessageValue('de',"common.button_text",Store),
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.cta_text : '') : ''),
    },
    { 'formType': 'url',
      'label': <IntlMessage Store={Store} messageId="common.url"/>,
      'type': 'text',
      'name': 'elements['+index+'][translation][url]',
      'id':'url'+index,
      'placeholder': IntlMessageValue('de',"common.url",Store),
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.url : '') : ''),
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/module_customer_steps.html',
    }
  ];

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'module_customer_steps'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>module_customer_steps</div>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.three_blocks"/>,'module_customer_steps',(newElement === false ? (element.translation !== undefined ? element.translation.de.headline : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.three_blocks"/>,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
        <Col  className={'accordion-body'}>
        <div id={'preview-container_module_customer_steps'+index} className={'preview-container'}  >
            <div className={"module m02 pb-4"+ element.class } id={ element.div_id +"_"+ element.id }>
              <div className="container">
                <div className="module__content">
                  <div className="module__content__topline">{element.translation.de.topline}</div>
                  <div className="module__content__headline"><h2>{element.translation.de.headline}</h2></div>
                  <div className="m02__steps row">
                    <div className="col-12 col-md-4 m02_step px-3">
                      <div className="h1">
                        <img src={element.translation.de.step_order_image.cdn_url}
                             alt=""/>
                      </div>
                      <div className="p">
                        {element.translation.de.step_order}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 m02_step px-3">
                      <div className="h1">
                        <img src={element.translation.de.step_delivered_image.cdn_url}
                             alt=""/>
                      </div>
                      <div className="p">
                        {element.translation.de.step_delivered}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 m02_step px-3">
                      <div className="h1">
                        <img src={element.translation.de.step_dispatch_at_image.cdn_url}
                             alt=""/>
                      </div>
                      <div className="p">
                        {element.translation.de.step_dispatch_at}
                      </div>
                    </div>
                  </div>
                  <div className="m02__cta text-center">
                    <a href={ element.translation.de.url }
                       className="btn btn--primary" title={ element.translation.de.topline }>{
                      element.translation.de.cta_text}</a>
                  </div>
                </div>
              </div>
        </div>
        </div>
          <div id={'edit_module_customer_steps'+index} style={{display:'none'}}>
          {PielersForm(newConfiguration)}
            {/*<Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

        </div>
        </Col>
        <div id={'settingstext'+index+'module_customer_steps'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
        {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.three_blocks"  Store={Store}/>,Store) : <></>)}

      </Row>

    </>
  )


}

export default module_customer_stepsWebBuilder;
