import React from 'react';
import DashbaordOverview from "./Pages/overview";

export const DashbaordPagesConfig = (value: any) => {

    return [
        {
            path: '/',
            elements: <DashbaordOverview value={value}/>,
        },
    ];
}

export const DashbaordMenuConfig = (value: any) => {
    return [
            {
                name: 'Dashboard',
                route: '/',
                icon: 'dashboard'
            },
        ]
}
