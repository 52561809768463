import deTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/de_DE.json";
import deMatomoMessages from "../modules/matomo/modules/localization/locales/de_DE.json";
import deCMSPageMessages from "../modules/CMS/Pages/modules/localization/locales/de_DE.json";
import deCMSCustomizeMessages from "../modules/CMS/customize/modules/localization/locales/de_DE.json";
import deCMSBlogMessages from "../modules/CMS/Blog/modules/localization/locales/de_DE.json";
import deCMSSnippetMessages from "../modules/CMS/Texte/modules/localization/locales/de_DE.json";
import deCMSTextblocksMessages from "../modules/CMS/TextBlocks/modules/localization/locales/de_DE.json";

import enTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/en_US.json";
import enMatomoMessages from "../modules/matomo/modules/localization/locales/en_US.json";
import enCMSPageMessages from "../modules/CMS/Pages/modules/localization/locales/en_US.json";
import enCMSCustomizeMessages from "../modules/CMS/customize/modules/localization/locales/en_US.json";
import enCMSBlogMessages from "../modules/CMS/Blog/modules/localization/locales/en_US.json";
import enCMSSnippetMessages from "../modules/CMS/Texte/modules/localization/locales/en_US.json";
import enCMSTextblocksMessages from "../modules/CMS/TextBlocks/modules/localization/locales/en_US.json";

export const RegisterLanguageDE = {
    ...deTicketsystemMessages,
    ...deMatomoMessages,
    ...deCMSPageMessages,
    ...deCMSCustomizeMessages,
    ...deCMSBlogMessages,
    ...deCMSSnippetMessages,
    ...deCMSTextblocksMessages
}

export const RegisterLanguageEN = {
    ...enTicketsystemMessages,
    ...enMatomoMessages,
    ...enCMSPageMessages,
    ...enCMSCustomizeMessages,
    ...enCMSBlogMessages,
    ...enCMSSnippetMessages,
    ...enCMSTextblocksMessages
}
