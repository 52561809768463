import React from 'react';
import Ticketoverview from "./Pages/overview";
import Issue from "./Pages/issue";
import NewIssue from "./Pages/newIssue";
import EditIssue from "./Pages/editIssue";
import LabelEdit from "./Pages/LabelEdit";
import {LanguageSwitcherValue} from "./components/LanguageSwitcher";

export const TicketPagesConfig = (value: any) => {

    return [
        {
            path: '/ticket/overview/:page',
            elements: <Ticketoverview value={value}/>,
        }, {
            path: '/ticket/Issue/:id',
            elements: <Issue value={value}/>,
        }, {
            path: '/ticket/edit/Issue/:id',
            elements: <EditIssue value={value}/>,
        }, {
            path: '/ticket/new/Issue',
            elements: <NewIssue value={value}/>,
        }, {
            path: '/ticket/Label/overview',
            elements: <LabelEdit value={value}/>,
        }
    ];
}

export const TicketMenuConfig = (value: any) => {
    return [{
        header: LanguageSwitcherValue("menu.header"),
        icon: 'support',
        menuitem: [
            {
                name: LanguageSwitcherValue("menu.item.dashboard"),
                route: '/ticket/overview/:page',
                icon: 'developer_board'
            },
            {
                name: LanguageSwitcherValue("menu.item.labels"),
                route: '/ticket/Label/overview',
                icon: 'label'
            },
        ]
    }]
}
