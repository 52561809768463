import Button from 'react-bootstrap/Button';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {notify_del_element} from '../../../component/const/notification.service';
import {error} from '../../../component/const/error.services';
import IntlMessage from '../../../component/const/IntlMessage';

const deleteElementsWebBuilder = (id:any,className:any = false,type:any=false,modalShow: boolean, setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
                        setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,typeid:any=false,content:any = false,setElements:any = [],Store:any) => {
  let setClass = '';
  if(className !== false) {
    setClass = className;
  }
//console.log(id)
  if(type !== false && typeid !== 0) {
    return(<>
      {/*<Button className={setClass} type={'button'} onClick={() => modalDel(setModalShow,setModalData,type,id,typeid)}>*/}
      <svg className={'bi bi-trash '+setClass} onClick={() => modalDel(setModalShow,setModalData,type,id,typeid)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
           viewBox="0 0 16 16" style={{height:'100%',marginTop: '-14px',width:'30px',textAlign:'left',float:'right',padding: '5px',background: 'rgba(0,0,0,.12)'}}>
        <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
        <path fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
      </svg>
      {/* </Button>*/}
      {modal(modalShow,setModalShow,modalData,Store)}
    </>)
  }  else {
    return(<>
      {/*  <Button className={setClass} type={'button'} onClick={() => deleteElement(id,false,false,content,setElements)}>*/}
      <svg className={'bi bi-trash '+setClass} onClick={() => deleteElement(id,false,false,content,setElements)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
           viewBox="0 0 16 16" style={{height:'100%',marginTop: '-14px',width:'30px',textAlign:'left',float:'right',padding: '5px',background: 'rgba(0,0,0,.12)'}}>
        <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
        <path fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
      </svg>
      {/*</Button>*/}

    </>)
  }



}
export default deleteElementsWebBuilder;


const deleteElement = (id: any, onHide:any =false,typeid:any=false,setDeleteElements:any = false,elements:any = []) => {
  var element = document.getElementById(id);
  if(onHide !== false && typeid !== 0){
  let api_URL;
  if (process.env.REACT_APP_MODE === 'LOCAL') {
    api_URL = process.env.REACT_APP_API_URL_LOCAL;
  } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
    api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
  } else if (process.env.REACT_APP_MODE === 'LIVE') {
    api_URL = process.env.REACT_APP_API_URL_LIVE;
  }  else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
    api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
  }

  let token = localStorage.getItem('token');
  // Identifizieren des Kindknotens


// Aufruf des Elternknotens, um dann dessen Kindknoten zu löschen
    if(element !== null && element !== undefined) {
      element.parentNode?.removeChild(element);
    }

    fetch(api_URL + 'element/delete/'+typeid+'/', {
      method: "delete",
      headers: {
        'Authorization': 'Bearer ' + token
      },

    })
      .then(response => response.json())
      .then(data => {
        notify_del_element();
      })
      .catch(e => {
        error(e);
      });
    onHide();
  } else if(typeid !== 0) {


   let allElements = elements;


   for(let e=0;e < allElements.length;e++){
      if (allElements[e].props.children[0].props.children.props.id === element) {
        setDeleteElements(e);
      }
    }

    if(element !== null && element !== undefined) {
      element.parentNode?.removeChild(element);
    }
  }

}

function modalDel(setModalShow:any,setModalData:any,titel:any,id:any,typeid:any){
  setModalShow(true)
  setModalData([titel,id,typeid])
}


function modal(modalShow: boolean, setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void,modalData:any,Store:any){

  return(<>
      <MyVerticallyCenteredModal
        show={modalShow}
        titel={modalData[0]}
        id={modalData[1]}
        typeid={modalData[2]}
        onHide={() => setModalShow(false)}
        Store={Store}
      />
    </>
  )
}

function MyVerticallyCenteredModal(props:any) {

  let identifier = 'Element:'+props.titel+' ID:'+props.typeid +' Position: '+ props.id?.replace(/\D/g, "")  ;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Position: {props.id?.replace(/\D/g, "")}-{props.titel}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4><IntlMessage Store={props.Store} messageId="common.confirm_delete"/></h4>
        <p>
          <IntlMessage Store={props.Store} messageId="common.assure_delete"  preperator="replace" param="[title]" replace={identifier}  />
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}><IntlMessage Store={props.Store} messageId="common.close" /></Button>
        <Button type={'button'} variant="danger"  onClick={() => deleteElement(props.id,props.onHide,props.typeid)}><IntlMessage Store={props.Store} messageId="common.delete"/></Button>
      </Modal.Footer>
    </Modal>
  );
}
