import React from 'react';
//import {LanguageSwitcherValue} from "../../shop/core/components/LanguageSwitcher";
import Configdetail from "./Pages/detail";

export const ConfigPagesConfig = (value:any) => {
  return [

    {
      path: '/Config/overview/:page',
      elements: <Configdetail value={value} />,
    },
  ];
}

export const ConfigMenuConfig = (value:any) => {
  return [
    {
      name: 'Template Settings',
      route: '/Config/overview/1',
      icon: 'description'
    }
  ]
}
