import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import React from 'react';
import useRefresh from './useRefresh';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import {Accordion} from 'react-bootstrap';
import deleteElements from '../../form-serilizer/elements/helper/deleteElements';
import {error} from './error.services';
import Spinner from 'react-bootstrap/Spinner';
import IntlMessage, {IntlMessageValue} from './IntlMessage';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";


/**
 * Helper for Spinner
 */
const timeout = document.getElementsByClassName('hideMe');
/**
 * Helper fopr Spinner
 */
export const hideElements = () => {
    return timeout[0].setAttribute('style', 'display:none');
};
/**
 * @typedef SpinnerJSXProps
 * @prop {string} messageId ID des Language Key
 * @prop {string} display Falls der Display Status vorab anders gesetzt werden soll
 * @prop {string} class Falls zusätzliche Klassen benötigt werden
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
interface SpinnerJSXProps {
    messageId: string,
    display?:string,
    class?:string,
    Store:any
}
/**
 * SpinnerJSX
 * @component
 * @example
 * <SpinnerJSX messageId={'common.loading'} />
 * @param {SpinnerJSXProps} props Übergabe werte zum Füllen des Spinners
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const SpinnerJSX: React.FC<SpinnerJSXProps> = (props) => {
    return (
        <>
            <div  className={'scrollbar-overlay spinner'+props.class} style={{display: props.display}}>
                <Spinner animation='border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                </Spinner><p><IntlMessage Store={props.Store} messageId={props.messageId} /> </p></div></>
    )
}
/**
 * Refresh die Seite
 * @param page
 * @param history
 * @param destination
 * @param to
 * @constructor
 */
const Refresh = (page: any,history:any,destination:any,to:any) => useRefresh(history, '/'+destination+'/'+to+'/' + page, '/'+destination+'/'+to+'/' + page);
/**
 * Blendet Spinner nach Success ein oder aus
 * @param page
 * @param history
 * @param destination
 * @param to
 */
export const handleSucces = (page: any,history:any,destination:any,to:any) => {
    let spinner = document.getElementsByClassName('spinner');
    let site = document.getElementsByClassName('site');
    spinner[0]?.setAttribute('style', 'display:block');
    site[0]?.setAttribute('style', 'display:none');
    if (history.location.pathname === '/'+destination+'/'+to+'/' + page) {
        Refresh(page,history,destination,to);
        setTimeout (show, 2600);
    } else {
        history.push('/'+destination+'/'+to+'/' + page);
        setTimeout (show, 2600);
    }
};
/**
 *  Blendet den Spinner ein oder aus
 */
export const show = () => {
    const spinnerElement = document.getElementsByClassName('spinner');
    const siteElement = document.getElementsByClassName('site');
    spinnerElement[0]?.setAttribute('style','display:none');
    siteElement[0]?.setAttribute('style','display:block');
}
/**
 * Neuladen der Seite
 * @param id
 * @param preroute
 * @param history
 */
const routeChange = (id: any, preroute: any, history: any) => {
    let path = preroute + id;
    if(history !== undefined) {
        history(path);
    }
};
/**
 * @typedef cardsProps
 * @prop {any} data
 * @prop {any[]} history
 * @prop {function} setModalShow
 * @prop {any} setModalData
 * @prop {any} allTaxon
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
interface CardsJSXProps {
    data: any,
    setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void,
    setModalData: any,
    allTaxon:any;
    destination:any;
}
/**
 * CardsJSX
 * @component
 * @example
 * <CardsJSX history={props.history} allTaxon={props.allTaxon} setModalShow={props.setModalShow} setModalData={props.setModalData} data={props.CardsConfiguration} />
 * @param {CardsJSXProps} props
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const CardsJSX: React.FC<CardsJSXProps> = (props) => {
    const history = useNavigate();
    let response = props.data.data.ressource;
    let row = [];
    let buttonTextColor = '';
    const Store = useSelector((state: any) => state)
    if (response.length > 0) {


        for (let x = (props.data.data.start === undefined ? 1 : props.data.data.start); x < response.length; x++) {
            let endabled: string = '';
            let enabledType: string = ''
            if(props.data.data.dontStatus  === undefined) {
                if (makedata(response[x], props.data.data.status) === 1 || makedata(response[x], props.data.data.status) === true) {
                    endabled = props.data.data.statusActive
                    enabledType = 'success'
                } else if(props.data.data.isapproved !== undefined && makedata(response[x], props.data.data.approved) === 'waiting') {
                    endabled = props.data.data.statusWaiting
                    enabledType = 'warning'
                    buttonTextColor =  props.data.data.statusWaitingColor
                } else {
                    endabled = props.data.data.statusDeactive
                    enabledType = 'danger'
                }
            } else {
                enabledType = 'success'
                endabled = props.data.data.dontStatus_label
            }
            let description: JSX.Element;
            if(props.data.data.descriptionShow === true) {
                if(props.data.data.description2 !== undefined) {
                    let role = makedata(response[x],props.data.data.description);
                    let company = makedata(response[x],props.data.data.description2);
                    description = (<><p>{role}</p><br /><p><strong>{company}</strong></p></>);
                } else {
                    if(props.data.data.descriptionMHP === true) {
                        let dataD = makedata(response[x], props.data.data.description)
                        let DHTML;
                        if(dataD === 1) {
                            DHTML = 'Administrator'
                        } else {
                            DHTML = 'User'
                        }
                        description = (<><p
                            dangerouslySetInnerHTML={{__html: DHTML}}/></>);
                    } else {
                        description = (<><p
                            dangerouslySetInnerHTML={{__html: makedata(response[x], props.data.data.description)}}/></>);
                    }
                }
            } else {
                description =(<></>)
            }
            let merchant: JSX.Element;
            if(props.data.data.showMerchant !== undefined && props.data.data.showMerchant === true){
                merchant = (<><p>{makedata(response[x],props.data.data.merchant)}</p></>);
            } else if(props.data.data.staticMerchant !== undefined && props.data.data.staticMerchant === true ){
                merchant =(<>Liceo24</>)
            } else {
                merchant =(<></>)
            }
            let parent: JSX.Element;
            if(props.data.data.parentShow === true && makedata(response[x],props.data.data.parent) !== '') {
                if(props.data.data.parentLabel === undefined) {
                    parent = (<><p><strong><IntlMessage Store={Store} messageId="card.parent.site" /></strong>: <a
                        href={props.data.data.preroute + response[x].parent_id}>{makedata(response[x], props.data.data.parent)}</a></p></>);
                } else {
                    let dataparent = makedata(response[x], props.data.data.parent);
                    if (dataparent !== null && makedata(response[x],props.data.data.tree_root) !== dataparent) {
                        let name = null;
                        let dataTree = makedata(response[x], props.data.data.parent)
                        for(let treename = 0; treename < props.allTaxon.length; treename++) {
                            if(props.allTaxon[treename]?.translatable_id === dataTree) {
                                name= props.allTaxon[treename]?.name
                            }
                        }
                        if(name === null) {
                            //name = dataTree;
                            name = 0;
                        }
                        if(name !== null && name !== 0) {
                            parent = (<><p><strong>{props.data.data.parentLabel}</strong>: <a
                                href={props.data.data.preroute + response[x].parent_id}>{name}</a></p></>);
                        } else {
                            parent =(<></>)
                        }
                    }  else {
                        parent =(<></>)
                    }
                }
            } else {
                parent =(<></>)
            }
            let tree;
            if(props.data.data.treeShow === true && makedata(response[x],props.data.data.tree_root) !== '' && makedata(response[x],props.data.data.tree_root) !== response[x].id) {
                let name = null;
                let dataTree = makedata(response[x], props.data.data.tree_root)
                for(let treename = 0; treename < props.allTaxon.length; treename++) {
                    if(props.allTaxon[treename]?.translatable_id === dataTree) {
                        name= props.allTaxon[treename]?.name
                    }
                }
                if(name === null) {
                    //name = dataTree;
                    name = 0;
                }
                if(name !== null && name !== 0) {
                    tree = (<><p><strong><IntlMessage Store={Store} messageId="category.main" /></strong>: <a
                        href={props.data.data.preroute + response[x].tree_root}>{name}</a></p></>);
                } else {
                    tree =(<></>)
                }
            } else {
                tree =(<></>)
            }
            let del;
            if(props.data.data.delete === true) {
                let link = props.data.data.deleteLink.replace('[ID]',response[x].id);
                del = (<>
                    <Button style={{border: '0',background: 'none',color: '#0d6efd'}} type={'button'} title={IntlMessageValue('de','del.'+props.destination,Store)} className={'float-end overviewButtonDel'}  onClick={() => modalDel(props.setModalShow,props.setModalData,makedata(response[x],props.data.data.title),response[x].id,link)}> <span
                        className="material-icons">delete</span></Button></>)
            }
            let link: string = '';
            if(props.data.data.indiBild === true) {

                if(response[x].hasOwnProperty('image')) {
                        if(props.data.data.imgproduct === true) {
                            if(makedata(response[x], props.data.data.img).includes(process.env.REACT_APP_CDN_URL)) {
                                link = makedata(response[x], props.data.data.img).replace(process.env.REACT_APP_CDN_URL,process.env.REACT_APP_CDN_URL_LOCAL)
                            } else {
                                if (process.env.REACT_APP_MODE === 'LOCAL') {
                                    link = process.env.REACT_APP_CDN_URL_LOCAL+''+makedata(response[x], props.data.data.img);
                                } else {
                                    link = process.env.REACT_APP_CDN_URL+''+makedata(response[x], props.data.data.img);
                                }

                            }

                        }else {
                            if (process.env.REACT_APP_MODE === 'LOCAL') {
                                link = process.env.REACT_APP_CDN_URL_LOCAL+''+makedata(response[x].image, props.data.data.img);
                            } else {
                                link = process.env.REACT_APP_CDN_URL+''+makedata(response[x].image, props.data.data.img);
                            }
                        }

                }  else if(props.data.data.img !== undefined){
                    if(makedata(response[x], props.data.data.img) === '') {
                        link = '/assets/images/noimage.jpg';
                    } else {
                        if(process.env.REACT_APP_NEW_BUCKET === 'True') {
                            if(makedata(response[x], props.data.data.img).includes('https://cdn.wum-solution.de/')) {
                                if(process.env.REACT_APP_MODE === 'LOCAL') {
                                    link =  makedata(response[x], props.data.data.img).replace(process.env.REACT_APP_CDN_URL,process.env.REACT_APP_CDN_URL_LOCAL)
                                } else {
                                    link = makedata(response[x], props.data.data.img);
                                }

                            } else {

                                link = (process.env.REACT_APP_MODE === 'LOCAL' ? process.env.REACT_APP_CDN_URL_LOCAL : process.env.REACT_APP_CDN_URL)+'/media/cache/marketplace_product_thumbnail/'+makedata(response[x], props.data.data.img);
                            }
                        } else {
                            if(props.data.data.cdnlink !== undefined && props.data.data.cdnlink === true) {
                                if (process.env.REACT_APP_MODE === 'LOCAL') {
                                    link = process.env.REACT_APP_CDN_URL_LOCAL + '' + makedata(response[x], props.data.data.img);
                                } else {
                                    link = process.env.REACT_APP_CDN_URL + '' + makedata(response[x], props.data.data.img);
                                }
                            } else{
                                if (process.env.REACT_APP_MODE === 'LOCAL') {
                                    link =  '' + makedata(response[x], props.data.data.img).replace(process.env.REACT_APP_CDN_URL,process.env.REACT_APP_CDN_URL_LOCAL);
                                } else {
                                    link =  '' + makedata(response[x], props.data.data.img);
                                }

                            }
                        }
                    }
                } else {
                    link = '/assets/images/noimage.jpg';
                }
                if(link === 'https://d12pljs2iucqg.cloudfront.net/' || link === 'http://cdn.agrica.tech/'){
                    link = '/assets/images/noimage.jpg';
                }
            } else {
                if (response[x].hasOwnProperty('image')) {
                    if(process.env.REACT_APP_NEW_BUCKET === 'True') {
                        if (process.env.REACT_APP_MODE === 'LOCAL') {
                            link = process.env.REACT_APP_CDN_URL_LOCAL+'' + makedata(response[x], props.data.data.img);
                        } else {
                            link = process.env.REACT_APP_CDN_URL+'' + makedata(response[x], props.data.data.img);
                        }
                    } else {
                        if (process.env.REACT_APP_MODE === 'LOCAL') {
                            link =  '' + makedata(response[x], props.data.data.img).replace('https://cdn.wum-solution.de/',process.env.REACT_APP_CDN_URL_LOCAL);
                        } else {
                            link =  '' + makedata(response[x], props.data.data.img);
                        }
                    }
                }  else if(props.data.data.img !== undefined){
                    if(process.env.REACT_APP_NEW_BUCKET === 'True') {
                        if (process.env.REACT_APP_MODE === 'LOCAL') {
                            link = process.env.REACT_APP_CDN_URL_LOCAL+'' + makedata(response[x], props.data.data.img);
                        } else {
                            link = process.env.REACT_APP_CDN_URL+'' + makedata(response[x], props.data.data.img);
                        }
                    } else {
                        if (process.env.REACT_APP_MODE === 'LOCAL') {
                            link =  '' + makedata(response[x], props.data.data.img).replace('https://cdn.wum-solution.de/',process.env.REACT_APP_CDN_URL_LOCAL);
                        } else {
                            link =  '' + makedata(response[x], props.data.data.img);
                        }
                    }

                }
            }
            let visited;
            if(props.data.data.Dontvisited === undefined) {
                let Previewlink: string = '';
                if (response[x].hasOwnProperty('image')) {
                    Previewlink = props.data.data.prelink + makedata(response[x], props.data.data.link) + '/';
                } else {
                    if (props.data.data.prelinksearch !== undefined) {
                        Previewlink = props.data.data.prelink.replace('[param]', makedata(response[x], props.data.data.prelinksearch));
                    }
                }




                visited = (<><a href={Previewlink} title={IntlMessageValue('de','preview.'+props.destination,Store)} className={'float-end'} target={'new'} style={{paddingTop: '5px'}}> <span
                    className="material-icons">visibility</span></a></>)
            } else {
                visited = '';
            }
            let finalTitle;
            let Titles = '';
            if(props.data.data.multiTitle  === true) {
                for(let t=0; t < props.data.data.Titles.length;t++){
                    Titles += makedata(response[x],props.data.data.Titles[t])+' ';
                }
                finalTitle = (<><Card.Title style={{height: '50px', overflow: 'hidden'}}>{Titles}</Card.Title></>)
            } else if(props.data.data.Titledirect  === true) {
                let titlebuild;
                if(props.data.data.Titledepth === 1) {

                    titlebuild= response[x][props.data.data.title[0]]
                } else if(props.data.data.Titledepth === 2) {

                    titlebuild = response[x][props.data.data.title[0]][props.data.data.title[1]]
                } else if(props.data.data.Titledepth === 3) {

                    titlebuild = response[x][props.data.data.title[0]][props.data.data.title[1]][props.data.data.title[2]]
                }

                finalTitle = (<><Card.Title style={{height: '50px', overflow: 'hidden'}}>{titlebuild}</Card.Title></>)
            } else {
                finalTitle = (<><Card.Title style={{height: '50px', overflow: 'hidden'}}>{makedata(response[x],props.data.data.title)}</Card.Title></>)
            }
            let img;
            if(props.data.data.img !== '') {
                if (props.data.data.product === true) {
                    if (makedata(response[x], props.data.data.img) === '') {
                        link = '/assets/images/noimage.jpg';
                    }
                    img = (<><Card.Img variant="top" src={
                        (process.env.REACT_APP_NEW_BUCKET === 'True' ? link.replace('https://d12pljs2iucqg.cloudfront.net','http://cdn.agrica.tech') : link)
                    } className={'cardsImg'}  /></>);
                }  else {
                    if (props.data.data.indiBild === true) {
                        img = (<><Card.Img variant="top"
                                           style={{maxWidth: '100%', minWidth: '100%', maxHeight: '229px', minHeight: '229px',objectFit: 'contain'}}
                                           src={(process.env.REACT_APP_NEW_BUCKET === 'True' ? link.replace('https://d12pljs2iucqg.cloudfront.net','http://cdn.agrica.tech') : link) } /></>);
                    } else {
                        img = (<><Card.Img  variant="top" src={(process.env.REACT_APP_NEW_BUCKET === 'True' ? link.replace('https://d12pljs2iucqg.cloudfront.net','http://cdn.agrica.tech') : link) } /></>);
                    }
                }
            }
            let countMerchant = (<></>);
            if(props.data.data.countMerchant === true) {
                countMerchant = (<><Badge bg="dark" style={{width: '100%',fontSize: '12px'}}>
                    <IntlMessage Store={Store} messageId="card.merchants" /> <Badge  bg="secondary">{response[x].merchant}</Badge>
                </Badge></>)
            }
            let countProducts = (<></>);
            if(props.data.data.countProducts === true) {
                countProducts = (<><Badge bg="dark" style={{width: '100%',fontSize: '12px'}}>
                    <IntlMessage Store={Store} messageId="card.products" /> <Badge bg="secondary">{response[x].products}</Badge>
                </Badge></>)
            }
            let count: JSX.Element = (<></>);
            if(props.data.data.countProducts === true || props.data.data.countMerchant === true) {
                count = (<><Row ><Col>{countMerchant}</Col><Col> {countProducts}</Col></Row></>)
            }
            let style;
            if(props.data.data.noDescription !== true) {
                style = {height:'130px',maxHeight: '130px', overflow: 'hidden'};
            } else {
                style = {};
            }

            let editButton = (<></>)
            if(localStorage.getItem('locale') === 'de' || localStorage.getItem('locale') === 'DE' || Store.locale.locale === 'DE' ) {
                editButton = <Button variant="primary" onClick={() => routeChange(response[x][props.data.data.router], props.data.data.preroute, history)}>{props.data.data.edit}
                    <IntlMessage Store={Store} messageId="common.cars.edit"/></Button>;
            } else if(localStorage.getItem('locale') === 'en' || Store.locale.locale === 'US') {
                editButton = <Button variant="primary" onClick={() => routeChange(response[x][props.data.data.router], props.data.data.preroute,history)}><IntlMessage Store={Store} messageId="common.cars.edit"/> {props.data.data.edit}
                </Button>;
            } else {
                editButton = <Button variant="primary" onClick={() => routeChange(response[x][props.data.data.router], props.data.data.preroute, history)}>{props.data.data.edit}
                    <IntlMessage Store={Store} messageId="common.cars.edit"/></Button>;
            }

            let badge = (<></>);
            if(props.data.data.switchButton !== undefined) {
               if(makedata(response[x], props.data.data.switchButtonParam) === 0) {
                   badge =    <span className="m-1 PielersBadge rounded-pill" style={{backgroundColor: 'black', color: 'white'}}
                   > <IntlMessage Store={Store} messageId={props.data.data.switchButtonTrueBadge}/></span>;
                   editButton = <Button variant="primary" onClick={() => routeChange(response[x][props.data.data.router], props.data.data.preroute, history)}>
                       <IntlMessage Store={Store} messageId={props.data.data.switchButtonTrueLabel}/></Button>;
               } else {
                   badge =    <span className="m-1 PielersBadge rounded-pill" style={{backgroundColor: 'orange', color: 'white'}}
                   > <IntlMessage Store={Store} messageId={props.data.data.switchButtonFalseLBadge}/></span>;
                   editButton = <Button variant="primary" onClick={() => routeChange(response[x][props.data.data.router], props.data.data.preroute, history)}>
                       <IntlMessage Store={Store} messageId={props.data.data.switchButtonFalseLabel}/></Button>;
               }
            }

            row.push(<><Col md={3} sm={3}><Card className={'CardOverview boxShadowCard'} style={{width: '100%', marginBottom: '20px'}}>
                <Button className={'headerboxColor float-end '+buttonTextColor} variant={enabledType} disabled>{endabled}</Button>
                {count}
                {img}
                <Card.Body>
                    {finalTitle}
                    <Card.Text style={style}>
                        {description}
                        {tree}
                        {parent}
                        {merchant}
                        {badge}
                    </Card.Text>
                    {del}
                    {visited}
                    {editButton}
                </Card.Body>
            </Card></Col></>)
        }
    }

    return (<>{row}</>);
}
/**
 *  Clear die Modal Box
 * @param setModalShow
 * @param setModalData
 * @param titel
 * @param id
 * @param link
 */
function modalDel(setModalShow:any,setModalData:any,titel:any,id:any,link:any){
    setModalShow(true)
    setModalData([titel,id,link])
}
/**
 * Verbindet gesuchte Daten mit Daten aus der Response
 * @param data
 * @param tableConfiguration
 */
function makedata(data: any, tableConfiguration: any){
    let datamap;

    if(tableConfiguration !== undefined) {
        let testing = tableConfiguration?.split('.');
        if (testing !== undefined && testing.length === 1 && data !== null) {
            if(data.hasOwnProperty(tableConfiguration) === true && data[tableConfiguration] !== null) {
                datamap = data[tableConfiguration];
            } else {
                datamap = '';
            }
        } else if (testing.length === 2) {
            if (data.hasOwnProperty(testing[0]) === true) {
                if (data[testing[0]] !== null && data[testing[0]].hasOwnProperty(testing[1]) === true) {
                    datamap = data[testing[0]][testing[1]];
                } else {
                    datamap = '';
                }
            } else {
                datamap = '';
            }
        } else if (testing.length === 3) {
            if (data.hasOwnProperty(testing[0]) === true) {
                if (data[testing[0]].hasOwnProperty(testing[1]) === true) {
                    if (data[testing[0]][testing[1]].hasOwnProperty(testing[2]) === true) {
                        datamap = data[testing[0]][testing[1]][testing[2]];
                    } else {
                        datamap = '';
                    }
                } else {
                    datamap = '';
                }
            } else {
                datamap = '';
            }
        } else if (testing.length === 4) {
            if (data.hasOwnProperty(testing[0]) === true) {
                if (data[testing[0]].hasOwnProperty(testing[1]) === true) {
                    if (data[testing[0]][testing[1]].hasOwnProperty(testing[2]) === true) {
                        if (data[testing[0]][testing[1]][testing[2]].hasOwnProperty(testing[3]) === true) {
                            datamap = data[testing[0]][testing[1]][testing[2]][testing[3]];
                        } else {
                            datamap = '';
                        }
                    } else {
                        datamap = '';
                    }
                } else {
                    datamap = '';
                }
            } else {
                datamap = '';
            }
        } else if (testing.length === 5) {
            if (data.hasOwnProperty(testing[0]) === true) {
                if (data[testing[0]].hasOwnProperty(testing[1]) === true) {
                    if (data[testing[0]][testing[1]].hasOwnProperty(testing[2]) === true) {
                        if (data[testing[0]][testing[1]][testing[2]].hasOwnProperty(testing[3]) === true) {
                            if (data[testing[0]][testing[1]][testing[2]][testing[3]].hasOwnProperty(testing[4]) === true) {
                                datamap = data[testing[0]][testing[1]][testing[2]][testing[3]][testing[4]];
                            } else {
                                datamap = '';
                            }
                        } else {
                            datamap = '';
                        }
                    } else {
                        datamap = '';
                    }
                } else {
                    datamap = '';
                }
            } else {
                datamap = '';
            }
        }
    }else {
        datamap = '';
    }
    return datamap;
}
/**
 * Formatiert Datum
 * @param dateString
 */
export const formatDate = (dateString:any) =>
{
    if(dateString?.includes('T')) {
        dateString = dateString.split('T');
        return dateString[0];
    }
}
/**
 * Formatiert Datum ins Deutsche oder US Format
 * @param dateString
 * @param format
 * @param nowFormat
 */
export const dateFormat = (dateString:any, format:any, nowFormat:any) => {
    let newDate;
    if(format === 'de' && nowFormat !== 'de') {
        let SplitDate = dateString?.split('-');
        if(typeof SplitDate !== 'undefined' && SplitDate.length > 0) {
            newDate = SplitDate[2] + '.' + SplitDate[1] + '.' + SplitDate[0]
        } else {
            newDate = dateString;
        }
    } else {
        newDate = dateString;
    }
    return newDate;
}
/**
 * Gibt eine Number in der Locale zurück punkt oder Komma
 * @param Locale
 * @param numbers
 */
export const number = (Locale:any,numbers:any) => {
    return numbers.toLocaleString(Locale, {minimumFractionDigits: 2, maximumFractionDigits: 2})
}
/**
 * Routing
 * @param path
 * @param history
 * @constructor
 */
export const GlobalrouteChange = (path: string,history:any) => {
    /**
     * Neuladen der Seite
     * @param path
     * @param history
     */
    const routeChange = (path: string,history:any) => {
        let paths = path;
        history.push(paths);
    }
    routeChange(path,history);
}
/**
 * Handelt den Submit
 * @param event
 * @param updateData
 * @param setValidated
 * @param id
 */
export const handleSubmitForm = (event: {currentTarget: any; preventDefault: () => void; stopPropagation: () => void}, updateData: any, setValidated: (value: (((prevState: boolean) => boolean) | boolean)) => void, id: any = 0) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        event.preventDefault();
        updateData(false,id)
    }
    setValidated(true);
};
/**
 * Erstellt den Header
 * @param name
 * @param nameID
 * @param Status
 * @param index
 * @param type
 * @param typeName
 * @param modalShow
 * @param setModalShow
 * @param modalData
 * @param setModalData
 * @param newElement
 * @param element
 * @param content
 * @param setElements
 * @constructor
 */
export const ElementsHead  = (name:any,nameID:any,Status:any,index:any,type:any,
                              typeName:any,modalShow:any,setModalShow:any,modalData:any,
                              setModalData:any,newElement:any,element:any,content:any = false,setElements:any = [],Store:any) => {

    let Margin;
    if(newElement === false) {
        Margin = '-6px';
    } else {
        Margin = '6px';
    }
    return (<><Row>
        <Col style={{width: '60px', maxWidth: '60px'}}>
            <h3 className={Status} style={{height:'100%',marginTop: '0px',padding:'0.08em 0em',width:'60px',textAlign:'center'}}>
        <span className="material-icons" style={{fontSize: 'xxx-large',marginTop: Margin,color:'black'}}>
     open_with
</span></h3></Col><Col>
        <Accordion.Button style={{background:'#f9fafb',border:'1px solid black'}}>
            <h3>{name}</h3>
            {deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements,Store)}
        </Accordion.Button>

    </Col>
        {/* <Col style={{width: '50px', maxWidth: '50px',marginTop: '10px'}}>
      {deleteElements(nameID.toLowerCase()+''+index,type,typeName,modalShow, setModalShow,modalData, setModalData,(newElement === false ? element.id : 0 ),content,setElements)}
    </Col>*/}
    </Row></>)
}
/**
 * Config für den Suneditor
 * @constructor
 */
export const SunEditorConfig = () => {
    return [
        // Default
        ['undo', 'redo'],
        ['formatBlock'],
        ['bold', 'underline', 'italic'],
        ['blockquote'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['link'],
        ['fullScreen', 'showBlocks', 'codeView'],
    ]
}
/**
 * Fetch Local
 * @param setloadings
 * @param url
 * @param local
 * @param row
 */
async function fetchLocal(setloadings:any,url:any,local:any,row:any = []) {
    let api_URL;
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        api_URL = process.env.REACT_APP_API_URL_LOCAL;
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        api_URL = process.env.REACT_APP_API_URL_LIVE;
    } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
        api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
    }
    let token = localStorage.getItem('token');
    await fetch(api_URL +''+url,
        {
            headers: {'Authorization':'Bearer ' + token}
        })
        .then(response => response.json())
        .then((data: any) => {
            row.push(data);
            localStorage.setItem(local, JSON.stringify(row));
            setloadings(true);
        })
        .catch(e => {
            error(e);
        });
}
/**
 * Löst den fetch aus
 * @param setloadings
 * @param url
 * @param local
 * @param row
 */
export const getPagesSelect = (setloadings: any, url: any, local: any, row: any = []) => {
    fetchLocal(setloadings,url,local,row,)
}
/**
 * Globaler zurück Button
 * @param history
 * @param langKey
 * @param addedClass
 */
export const btn_back = (history:any,langKey:any = "common.abort",addedClass:any = '',Store:any) =>{
    return (
        <>
            <Button variant='light' style={{float: 'left', marginLeft: '15px'}} className={addedClass} onClick={() => history.goBack()}>{<IntlMessage Store={Store} messageId={langKey} />}</Button>
        </>
    );
}

/**
 * Prüft ob ShippingPrice korreckt angegeben wurde und wen nicht wird es korigiert
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const _handleValidateShipping = () => {
    if(document.getElementById('shippingprice') !== null  && document.getElementById('shippingprice') !== undefined){
        let shippingprice = (document.getElementById('shippingpriceInput') as HTMLInputElement).value
        let result = shippingprice.match(",");
        if(result === null){
            let modifierResult;
            if(shippingprice === '') {
                modifierResult = '0,00';
            } else {
                modifierResult = shippingprice+',00';
            }
            (document.getElementById('shippingpriceInput') as HTMLInputElement).value = modifierResult;
            return false;
        }
        return true;
    } else {
        return true;
    }
}
/**
 * currencyFormat give out currency string
 * @param {float} price float of Price
 * @param {string} currency string of currency
 * @example
 * currencyFormat(netto,currency)
 */
export const  currencyFormat = (price:number,currency:string) => {
    let tmp = Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
    }).format(price);
    return tmp;
}


/**
 * @typedef validatemessageProps
 * @prop {any} valid
 * @author Marcel Ugurcu
 * @version 0.0.1
 *
 */
interface ValidateMessageProps {
    valid: any,
    Store:any
}
/**
 * Validatemessage
 * @component
 * @example
 * <Validatemessage valid={data.valid} />
 * @param {ValidateMessageProps} props Übergabe werte zum Füllen des Spinners
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const ValidateMessage: React.FC<ValidateMessageProps> = (props) => {
    const validate = (props.valid !== undefined ? props.valid : 'einen gültigen Wert');
    return (<>  <Form.Control.Feedback type="invalid">
        <IntlMessage Store={props.Store} messageId="notification.invalid_value" preperator={'replace'} replace={validate} param={'[valid]'} />
    </Form.Control.Feedback></>);
}

/**
 * Merge two Objects into Array
 * @param {any} obj1
 * @param {any} obj2
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const MergeTwoObjecttoArray = (obj1:any,obj2:any) => {
    let row:any =[];
    for(let option = 0; option<obj1.length; option++){
        row.push(obj1[option])
    }
    for(let options = 0; options<obj2.length; options++){
        row.push(obj2[options])
    }

    return row;
}


interface allproductProps {
    response: any,
    Store:any
}

export const AllProduct: React.FC<allproductProps> = (props) => {

    let point = props.response.allproducts;
    let row: any[] = [];
    for(let x = 0; x < point.length;x++){

        row.push(<>

            <ListGroup.Item>
                <Row>
                    <Col xl={10} md={10} sm={10}>{point[x].name }</Col>
                    <Col xl={2} md={2} sm={2}><Button variant={'info'} className={'float-end'} type={'button'} target={'_blank'} href={'/Product/Detail/'+point[x].translatable_id}>
                        <IntlMessage Store={props.Store} messageId="added.products.button" />
                    </Button></Col>
                </Row>
            </ListGroup.Item>
        </>)

    }

    return (
        <>
            <ListGroup>
                {row}
            </ListGroup>
        </>
    )

}

interface allmerchantProps {
    response: any,
    Store:any
}


export const AllMerchant: React.FC<allmerchantProps> = (props) => {

    let point = props.response.allmerchant;
    let row: any[] = [];
    for(let x = 0; x < point.length;x++){

        row.push(<>
            <ListGroup.Item>
                <Row>
                    <Col xl={10} md={10} sm={10}>{point[x].company }</Col>
                    <Col xl={2} md={2} sm={2}><Button variant={'info'} className={'float-end'} target={'_blank'} type={'button'} href={'/merchant/Detail/'+point[x].id}>
                        <IntlMessage Store={props.Store} messageId="added.merchants.button" />
                    </Button></Col>
                </Row>
            </ListGroup.Item>
        </>)

    }

    return (
        <>
            <ListGroup>
                {row}
            </ListGroup>
        </>
    )

}

export const SetDefaultLanguage = () => {

    let locale;
    if(localStorage.getItem('locale') === 'de') {
        locale = {
            languageId: 'deutsch',
            locale: 'de',
            name: 'Deutsch',
            icon: 'de',
        };
    } else if(localStorage.getItem('locale') === 'en') {
        locale = {
            languageId: 'english',
            locale: 'en',
            name: 'Englisch',
            icon: 'us',
        };
    } else {
        locale = {
            languageId: 'deutsch',
            locale: 'de',
            name: 'Deutsch',
            icon: 'de',
        };
    }
    return locale;
}

export const ApiURL = () => {
    let api_URL;
    if (process.env.REACT_APP_MODE === 'LOCAL') {
        api_URL = process.env.REACT_APP_API_URL_LOCAL;
    } else if (process.env.REACT_APP_MODE === 'DEVELOPMENT') {
        api_URL = process.env.REACT_APP_API_URL_DEVELOPMENT;
    } else if (process.env.REACT_APP_MODE === 'LIVE') {
        api_URL = process.env.REACT_APP_API_URL_LIVE;
    } else if (process.env.REACT_APP_MODE === 'KLIMABAUHELD') {
        api_URL = process.env.REACT_APP_API_URL_KLIMABAUHELD;
    }

    return api_URL;
}

export const getToken = () => {
    return localStorage.getItem('token');
}
