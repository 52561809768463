import React from 'react';
import PielersForm from '../../index'
import {base, basesettings} from './base';
import IntlMessage from '../../../component/const/IntlMessage';
import CopyElements from "../../../component/const/CopyElements";
import WebBuilderElementsHead from "../../../component/const/WebBuilderElementsHead";
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";

function anchorWebBuilder(data: any, element:any,i:any, newElement:any = false,newelementIndex:any = 0,modalShow: boolean,
              setModalShow: (value: (((prevState: boolean) => boolean) | boolean)) => void, modalData: any[],
              setModalData: (value: (((prevState: any[]) => any[]) | any[])) => void,
              content:any = [],setElements:any = [],Store:any) {

  const index = (newElement === false ? i : newelementIndex)

  const configuration = [
    {
      'formType': 'input',
      'data': data,
      'label': <IntlMessage Store={Store} messageId="module.anchor.label" />,
      'name': 'elements['+index+'][translation][anchor]',
      'value': (newElement === false ? (element.translation !== undefined ? element.translation.de.anchor : '') : ''),
      'legende': <IntlMessage Store={Store} messageId="module.anchor.legende" />
    },
    {
      'formType': 'input',
      'name': 'elements[' + index + '][template]',
      'type': 'hidden',
      'value': 'Elements/anchor.html',
    }]

  let newConfiguration = configuration.concat(base(element,i,newElement,newelementIndex));

  let Status;
  if(element.visible === 1) {
    Status = 'elementActive';
  } if(element.visible === 0){
    Status = 'elementDeactive';
  } else if(newElement === true) {
    Status = 'elementActive';
  }
  return (
    <>
      <Row  id={'anchor'+index} className={'boxShadowCard card'}   style={{paddingBottom: '15px',paddingTop:'15px',width: '100%',margin:'0px 0px 10px 0px'}}>
        <div id={'Teaser'}>anchor</div>
        {WebBuilderElementsHead(<IntlMessage Store={Store} messageId="module.anchor" />,'anchor',(newElement === false ? (element.translation !== undefined ? element.translation.de.anchor : '') : ''),Status,index,'elementButton',<IntlMessage Store={Store} messageId="module.anchor" />,
            modalShow,setModalShow,modalData, setModalData,newElement,element,content,setElements,Store,'','',element.id)}
          <Col  className={'accordion-body'}>
            <div id={'preview-container_anchor'+index}  className={'preview-container'}>
              {(newElement === false ?
                  <div className={"module "+ element.class } id={ element.div_id +"_"+ element.id }>
                    <div className="m25_anchor" id={ element.translation.de.anchor }>
                    </div>
                  </div> : <></>)}
            </div>
            <div id={'edit_anchor'+index} style={{display:'none'}}>
            {PielersForm(newConfiguration)}

           {/* <Accordion.Body>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
              <Tab eventKey="home" title={<IntlMessage Store={Store} messageId="common.general" />} key={'allgemein'} id={'allgemein'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(newConfiguration)}
              </Tab>
              <Tab eventKey="Einstellungen" title={<IntlMessage Store={Store} messageId="settings.settings.header" locale={'de'} />} key={'Einstellungen'} style={{backgroundColor:"White", padding: "15px",marginTop: "-15px"}}>
                {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
              </Tab>
            </Tabs>*/}

            </div>
            </Col>
        <div id={'settingstext'+index+'anchor'} className={'settingsWebBuilderModul boxShadow '} style={{display:'none'}}>

          {PielersForm(basesettings(element,i,newElement,newelementIndex,Store))}
        </div>
            {(newElement === false ? CopyElements(element.id,<IntlMessage messageId="module.anchor"  Store={Store}/>,Store) : <></>)}

          </Row>
    </>
  )


}

export default anchorWebBuilder;
