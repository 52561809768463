import {CMSMenuConfig} from "../modules/CMS";
import {MatomoMenuConfig} from "../modules/matomo/modules";
import {TicketMenuConfig} from "../modules/ticketsystem/modules";
import {ShopMenuConfig} from "../modules/shop";
import {monitoringMenuConfig} from "../modules/monitoring/modules";
import {SettingsMenuConfig} from "../modules/settings";
import {DashbaordMenuConfig} from "../modules/CMS/dashboard/modules";
import {BlogMenuConfig} from "../modules/CMS/Blog/modules";
import {PagesMenuConfig} from "../modules/CMS/Pages/modules";
import {TextBlocksMenuConfig} from "../modules/CMS/TextBlocks/modules";
import {FormularMenuConfig} from "../modules/CMS/Formular/modules";
import {CustomizerMenuConfig} from "../modules/CMS/customize/modules";
import {ShowDialog} from "../@WUM/core/sidebar/sidebar";


export const RegisterSitemap = (value: any) => {

    switch (process.env.REACT_APP_MODE_PLATTFORM) {
        case 'CMS':
            return [
                ...CMSMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)

            ]
        case 'CMSwithMatomo':
            return [
                ...CMSMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)

            ]
        case 'SHOP':
            return [
                ...CMSMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
        case 'SHOPwithMatomo':
            return [
                ...CMSMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
        case 'ProductCatalog':
            return [
                ...CMSMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
        case 'ProductCatalogwithMatomo':
            return [
                ...CMSMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
        default:
            return [
                ...CMSMenuConfig(value),
                ...ShopMenuConfig(value),
                ...SettingsMenuConfig(value),
                ...monitoringMenuConfig(value),
                ...MatomoMenuConfig(value),
                ...TicketMenuConfig(value),
                ...Media(value)
            ]
    }

}

const Media = (value:any) => [
    {
        header: 'Media',
        icon: 'perm_media',
        menuitem: [
            {
                name: 'Galerie',
                route: '/',
                icon: 'collections',
                onClick:ShowDialog
            },
        ]
    }]
